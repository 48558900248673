import { defineStore } from "pinia";
export const usePartsStore = defineStore("parts", {
  state: () => ({
    // parts: {
    //   ID_0_COPY_0: {
    //     name: "ID_0_COPY_0",
    //     outline: [
    //       { x: 104.21, y: 104.205 },
    //       { x: 104.21, y: 115.79 },
    //       { x: 115.79, y: 115.79 },
    //       { x: 115.79, y: 104.205 },
    //     ],
    //     target: { x: 111.097, y: 108.621 },
    //   },
    //   ID_1_COPY_0: {
    //     name: "ID_1_COPY_0",
    //     outline: [
    //       { x: 103.921, y: 149.567 },
    //       { x: 103.921, y: 161.153 },
    //       { x: 115.501, y: 161.153 },
    //       { x: 115.501, y: 149.567 },
    //     ],
    //     target: { x: 110.809, y: 153.983 },
    //   },
    //   ID_2_COPY_0: {
    //     name: "ID_2_COPY_0",
    //     outline: [
    //       { x: 148.336, y: 105.862 },
    //       { x: 148.336, y: 117.447 },
    //       { x: 159.916, y: 117.447 },
    //       { x: 159.916, y: 105.862 },
    //     ],
    //     target: { x: 155.222, y: 110.279 },
    //   },
    //   ID_3_COPY_0: {
    //     name: "ID_3_COPY_0",
    //     outline: [
    //       { x: 107.593, y: 60.105 },
    //       { x: 107.593, y: 71.69 },
    //       { x: 119.173, y: 71.69 },
    //       { x: 119.173, y: 60.105 },
    //     ],
    //     target: { x: 114.478, y: 64.523 },
    //   },
    //   ID_4_COPY_0: {
    //     name: "ID_4_COPY_0",
    //     outline: [
    //       { x: 63.529, y: 100.026 },
    //       { x: 63.529, y: 111.612 },
    //       { x: 75.109, y: 111.612 },
    //       { x: 75.109, y: 100.026 },
    //     ],
    //     target: { x: 70.417, y: 104.444 },
    //   },
    // },
    parts: {
      "0_0": {
        name: "0_0",
        outline: [
          {
            x: 47.223,
            y: 218.174,
          },
          {
            x: 47.223,
            y: 238.174,
          },
          {
            x: 67.223,
            y: 238.174,
          },
          {
            x: 67.223,
            y: 218.174,
          },
          {
            x: 47.223,
            y: 218.174,
          },
        ],
        target: {
          x: 57.223,
          y: 228.174,
        },
      },
      "0_1": {
        name: "0_1",
        outline: [
          {
            x: 229.146,
            y: 219.225,
          },
          {
            x: 229.146,
            y: 239.225,
          },
          {
            x: 249.146,
            y: 239.225,
          },
          {
            x: 249.146,
            y: 219.225,
          },
          {
            x: 229.146,
            y: 219.225,
          },
        ],
        target: {
          x: 239.146,
          y: 229.225,
        },
      },
      "0_2": {
        name: "0_2",
        outline: [
          {
            x: 44.715,
            y: 45.365,
          },
          {
            x: 44.715,
            y: 65.365,
          },
          {
            x: 64.715,
            y: 65.365,
          },
          {
            x: 64.715,
            y: 45.365,
          },
          {
            x: 44.715,
            y: 45.365,
          },
        ],
        target: {
          x: 54.715,
          y: 55.365,
        },
      },
      "0_3": {
        name: "0_3",
        outline: [
          {
            x: 227.82,
            y: 51.068,
          },
          {
            x: 227.82,
            y: 71.068,
          },
          {
            x: 247.82,
            y: 71.068,
          },
          {
            x: 247.82,
            y: 51.068,
          },
          {
            x: 227.82,
            y: 51.068,
          },
        ],
        target: {
          x: 237.82,
          y: 61.068,
        },
      },
      "0_4": {
        name: "0_4",
        outline: [
          {
            x: 140,
            y: 140,
          },
          {
            x: 140,
            y: 160,
          },
          {
            x: 160,
            y: 160,
          },
          {
            x: 160,
            y: 140,
          },
          {
            x: 140,
            y: 140,
          },
        ],
        target: {
          x: 150,
          y: 150,
        },
      },
    },
    excludedParts: [""],
    printState: "",
    currentPosition: null,
  }),
  getters: {
    getParts: (state) => {
      return state.parts;
    },
    getPosition: (state) => {
      return state.currentPosition;
    },
    getIsPartCurrent() {
      return (partName) => {
        return this.currentPart === partName;
      };
    },
    getIsPartExcluded() {
      return (partName) => {
        return this.excludedParts.includes(partName);
      };
    },
    getPartPos() {
      return (partName) => {
        const p = this.getParts[partName];
        return p === null || p === void 0 ? void 0 : p.target;
      };
    },
    getPartSVG() {
      return (partName) => {
        const part = this.getParts[partName];
        let svg = "";
        let op = "M";
        part.outline.forEach((p) => {
          svg += `${op}${p.x},${p.y}`;
          op = "L";
        });
        svg += "z";
        return svg;
      };
    },
    getPrintState: (state) => {
      return state.printState;
    },
  },
  actions: {
    // 核心svg数据处理，存储的方法
    partUpdate(payload) {
      if (payload.length > 0) {
        const partMap = {};
        payload.forEach((obj) => {
          const name = obj.name;
          const part = {
            name,
            outline: [],
            target: null,
          };
          if ("center" in obj && obj.center.length === 2) {
            part.target = { x: obj.center[0], y: obj.center[1] };
          }
          if ("polygon" in obj) {
            part.outline = obj.polygon.map((p) => {
              return { x: p[0], y: p[1] };
            });
          }
          partMap[name] = part;
        });
        console.log("排除对象SVG功能期望的数据处理", JSON.stringify(partMap));
        this.parts = Object.freeze(partMap);
      }
    },
    printStatsUpdate(payload) {
      this.printState(payload.state);
    },
    printCurrentPosition(current_position) {
      // console.log("传过来的是什么坐标", current_position);
      this.currentPosition = current_position;
    },
  },
});
