<template>
  <div
    class="comp-TemperatureControl"
    :class="{ controlAnimal: !state.foldFlag }"
  >
    <HeaderBar
      :title="$t('temperatureControl.temp')"
      class="header"
      :icon="'&#xe644;'"
    >
      <template v-slot:right>
        <!-- <div class="menu">
          <div class="fan-switch">
            风扇开关
            <el-switch size="large" v-model="fanSwitch" @change="fanHandler" />
          </div>
          <el-button size="small" @click="heatersOff">
            <i class="iconfont" style="font-size: 12px">&#xe648;</i>
            一键制冷</el-button
          >
        </div> -->
        <!-- 收起面板 -->
        <div class="fold">
          <!-- 折叠 -->
          <i class="iconfont" @click.stop="foldThePage" v-if="state.foldFlag"
            >&#xe65a;</i
          >
          <!-- 展开 -->
          <i class="iconfont" @click.stop="openThePage" v-else>&#xe659;</i>
        </div>
      </template>
    </HeaderBar>
    <div class="content" v-show="state.foldFlag">
      <el-table class="table" :data="state.tableData" style="width: 100%">
        <el-table-column
          prop="project"
          :label="$t('temperatureControl.progrems')"
          width="100"
          align="center"
        >
          <template v-slot="scope">
            <i class="iconfont" :style="{ color: scope.row.iconColor }"
              >&#xe644;</i
            >
            <span style="margin-left: 10px">{{ scope.row.project }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="temperature"
          :label="$t('temperatureControl.nows')"
          align="center"
          width="100"
        />
        <el-table-column
          prop="target"
          :label="$t('temperatureControl.goals')"
          align="center"
        >
          <template v-slot="scope">
            <div
              :class="[
                'speDiv',
                { 'focus-style': scope.row.focusFlag == true },
              ]"
            >
              <el-input
                v-model="scope.row.target"
                @change="temperHandler(scope.row, scope.row.target)"
                @focus="onInputFocus(scope.row)"
                @blur="onInputBlur(scope.row)"
                ><template #suffix>
                  <span>°C</span>
                </template>
              </el-input>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="chart" ref="chartRef"></div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, onMounted, reactive, ref, watch } from "vue";
import HeaderBar from "@/components/HeaderBar";
import { getNowFormatDate } from "@/utils/public";
import { useMainStore } from "@/stores";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
const { locale } = useI18n();
// 唤醒国际化
const { $t } = getCurrentInstance().proxy;
const state = reactive({
  // 折叠面板
  foldFlag: true,
  tableData: [
    {
      project: $t("temperatureControl.penzui"),
      temperature: "/",
      target: "",
      idx: 3,
      iconColor: "#b57326",
      focusFlag: false,
      type: "extruder",
    },
    {
      project: $t("temperatureControl.hotBed"),
      temperature: "/",
      target: "",
      idx: 0,
      iconColor: "#2d76a0",
      focusFlag: false,
      type: "bed0",
    },
    // {
    //   project: "Header Bed1",
    //   temperature: "",
    //   target: "",
    //   idx: 1,
    //   iconColor: "#2d76a0",
    //   focusFlag: false,
    // },
    // {
    //   project: "Header Bed2",
    //   temperature: "",
    //   target: "",
    //   idx: 2,
    //   iconColor: "#2d76a0",
    //   focusFlag: false,
    // },
  ],
  // websocket服务
  socket: null,
  echart: null,
  chart: null,
  product: ["product"],
  source: [],
  // 事件触发中间件
  messageCenter: null,
  bedTem: "/",
  extruder: "/",
});
const store1 = useMainStore();
watch(
  [() => store1.targetBedTemp0, () => store1.targetNozzleTemp],
  () => {
    for (const item of state.tableData) {
      if (item.type === "extruder") {
        item.target = store1.targetNozzleTemp;
      }
      if (item.type === "bed0") {
        item.target = store1.targetBedTemp0;
      }
    }
  },
  { immediate: true }
);
// 监听语言切换,动态整理国际化
watch(
  () => locale.value,
  () => {
    // 后台动深层态数据手动国际化
    console.log("status", locale.value);
    // 常用开关名称
    state.tableData[0].project = $t("temperatureControl.penzui");
    state.tableData[1].project = $t("temperatureControl.hotBed");
  }
);
watch(
  () => store1.nozzleTemp,
  (nozzleTemp) => {
    state.tableData[0].temperature =
      (+nozzleTemp).toFixed(2) || (+state.tableData[0].temperature).toFixed(2); // 喷嘴当前温度
  },
  { immediate: true }
);

watch(
  () => store1.bedTemp0,
  (bedTemp0) => {
    state.tableData[1].temperature =
      (+bedTemp0).toFixed(2) || (+state.tableData[1].temperature).toFixed(2); // 热创当前温度
  },
  { immediate: true }
);

// 最大喷嘴温度
watch(
  () => store1.maxNozzleTemp,
  (maxNozzleTemp) => {
    console.log("最大喷嘴温度: ", maxNozzleTemp);
  },
  { immediate: true }
);
// 最大热床温度
watch(
  () => store1.maxBedTemp,
  (maxBedTemp) => {
    console.log("最大热床温度: ", maxBedTemp);
  },
  { immediate: true }
);

let fanSwitch = ref(!!store1.fan);
watch(store1.fan, (val) => (fanSwitch.value = !!val));
// 调取socket指令控制风扇开关
// const fanHandler = (val) => {
//   state.socket.sendMsg({
//     method: "set",
//     params: { fan: +val },
//   });
// };
// // 调取socket指令控制制冷开关
// const heatersOff = () => {
//   state.socket.sendMsg({
//     method: "set",
//     params: { heatersOff: 1 },
//   });
// };
// 折叠面板
const foldThePage = () => {
  // console.log("准备缩小面板啦");
  state.foldFlag = false;
};
// 展开面板
const openThePage = () => {
  // console.log("准备展开面板啦");
  state.foldFlag = true;
};
// 调取socket指令控制温度
const temperHandler = (row, val) => {
  // 三个热床集合
  const arrayCheck = [0, 1, 2];
  if (+val <= 0 || +val >= 300) {
    ElMessage({
      type: "warning",
      message: $t("temperatureControl.temFanwei"),
    });
    return;
  }
  if (arrayCheck.includes(row.idx)) {
    state.socket.sendMsg({
      method: "set",
      params: {
        bedTempControl: {
          num: row.idx,
          val: +val,
        },
      },
    });
  } else {
    // 唯一的喷嘴
    state.socket.sendMsg({
      method: "set",
      params: { nozzleTempControl: +val },
    });
  }
};

function onInputFocus(param) {
  param.focusFlag = true;
}
function onInputBlur(param) {
  param.focusFlag = false;
}

const setOpt = () => {
  const arr = ["product"];
  const series = state.tableData.map((m) => {
    arr.push(m.project);
    return {
      type: "line",
      areaStyle: {
        opacity: 0.1,
      },
      smooth: true,
      symbol: "none",
    };
  });
  // if (store.chartData.length) {
  //   state.source = store.chartData;
  // } else {
  state.source = [arr];
  for (let i = 60; i--; i > 0) {
    state.source.push([getNowFormatDate(i), 0, 0]);
    // }
  }

  let option = {
    color: ["#ED970F", "#289ADA"],
    title: {
      text: "temperature℃",
      left: 40,
      top: 0,
      textStyle: {
        color: "#CBCBCC",
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: "axis",
      textStyle: {
        align: "left",
        color: "#dbdbdc",
      },
      backgroundColor: "#515157",
      borderColor: "#515157",
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: 10,
      top: 30,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#46464A"],
        },
      },
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#46464A"],
        },
      },
    },
    dataset: {
      source: state.source,
    },
    series,
  };
  return option;
};
onMounted(() => {
  const { proxy } = getCurrentInstance();
  state.socket = proxy.$webSocket;
  state.messageCenter = proxy.$messageCenter;
  const chartDom = document.querySelector(".chart");
  let opt = setOpt();
  const chart = proxy.$echarts.init(chartDom);
  opt && chart.setOption(opt);
  // initChart();
  // 监听收到消息
  state.messageCenter.on("message_change", (data) => {
    if (data === "ok") return;
    const responData = JSON.parse(data);
    const { nozzleTemp, bedTemp0 } = responData;
    if (nozzleTemp || bedTemp0) {
      const pre = state.source[state.source.length - 1];
      let curTime = getNowFormatDate(0);
      const curData = [curTime, +nozzleTemp || pre[1], +bedTemp0 || pre[2]];
      if (pre[0] === curTime) {
        if (state.source.length === 61) {
          state.source.splice(state.source.length - 1, 1);
        } else return;
      }
      state.source.push(curData);
      if (state.source.length > 120) state.source.splice(1, 1);
      // store1.chartData = state.source;
      chart.setOption({
        dataset: {
          source: state.source,
        },
      });
    }
  });
  window.addEventListener("resize", () => chart.resize());
});
</script>
<style lang="scss" scoped>
.controlAnimal {
  height: 40px !important;
}
// 兼容移动设备
@media screen and (max-width: 1200px) {
  .comp-TemperatureControl {
    :deep() .com-HeaderBar {
      .firstDiv {
        width: 4.5rem !important;
      }
    }
  }
}
.comp-TemperatureControl {
  width: 100%;
  height: 503px;
  @include theme_layout();
  .header {
    .menu {
      display: flex;
      align-items: center;
      font-size: 12px;
      .fan-switch {
        > div {
          margin-left: 6px;
        }
      }
      .el-button {
        width: 120px;
        height: 24px;
        margin-left: 33px;
        border-radius: 12px;
        i {
          margin-right: 6px;
        }
      }
    }
    .fold {
      cursor: pointer;
      .iconfont {
        color: #d2d2da;
        font-size: 8px;
      }
    }
  }
  .content {
    :deep() .el-table {
      .el-table__cell {
        padding: 0;
        height: 42px;
      }
      .el-table__header-wrapper {
        background: #313135;
      }
    }
    // 兼容移动设备
    // @media screen and (max-width: 1200px) {
    //   .speDiv {
    //     margin-right: 5rem !important;
    //   }
    // }
    .speDiv {
      .el-input {
        width: 120px;
      }
    }
    .focus-style {
      .el-input {
        width: 120px;
        border: 1px solid $theme-btn-color;
        :deep().el-input__wrapper {
          .el-input__suffix {
            .el-input__suffix-inner {
              color: $theme-btn-color;
            }
          }
        }
      }
    }

    .chart {
      height: 280px;
      margin-top: 15px;
    }
  }
}
</style>
