<template>
  <div class="control-main" :class="{ controlAnimal: !state.foldFlag }">
    <HeaderBar :title="state.statusTitle" :icon="'&#xe65c;'">
      <template v-slot:right>
        <div class="right-func">
          <div class="continue-func" v-show="showContinue">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="continueTip"
              placement="top"
            >
              <i class="iconfont" v-show="!!continueTip">&#xe7ba;</i>
            </el-tooltip>

            <el-button
              size="small"
              @click="setContinue"
              v-show="curContinueVal === 'repoPlrStatus'"
              >{{ $t("printStatus.goOn") }}</el-button
            >
            <el-button
              size="small"
              @click="setCancelContinue"
              v-show="curContinueVal === 'repoPlrStatus'"
              >{{ $t("printStatus.cancel") }}</el-button
            >
          </div>
          <div
            class="start-func"
            v-if="state.printStatus === 1 || state.printStatus === 5"
          >
            <div class="spe-button go-btn" @click.stop="start">
              <el-button size="small" v-if="state.printStatus === 1">
                <i class="iconfont">&#xe61f;</i>
                {{ $t("printStatus.pause") }}</el-button
              >
              <el-button size="small" v-if="state.printStatus === 5">
                <i class="iconfont">&#xe617;</i>
                {{ $t("printStatus.goOn") }}</el-button
              >
              <!-- 暂停运行状态 -->
            </div>

            <el-button size="small" @click.stop="stop">
              <i class="iconfont">&#xe61c;</i>
              {{ $t("printStatus.off") }}</el-button
            >
          </div>
          <!-- 收起面板 -->
          <div class="fold">
            <!-- 折叠 -->
            <i class="iconfont" @click.stop="foldThePage" v-if="state.foldFlag"
              >&#xe65a;</i
            >
            <!-- 展开 -->
            <i class="iconfont" @click.stop="openThePage" v-else>&#xe659;</i>
          </div>
        </div>
      </template>
    </HeaderBar>
    <div class="containMain" v-show="state.foldFlag">
      <!-- 主体容器 -->
      <div class="middleContent">
        <div class="leftPrintshow">
          <img
            :style="
              state.effective
                ? 'width: 134px; height: 80%'
                : 'width: fit-content'
            "
            :src="state.gcodePriview"
            :alt="$t('printStatus.gcodePre')"
            srcset=""
          />
          <!-- 水波进度条 -->
          <canvas id="c"></canvas>
        </div>
        <div class="rightPrintshow">
          <div class="innerFlie">
            <span class="span1">File:</span>
            <span class="span2">{{ state.fileName }}</span>
          </div>
          <div class="innerFlie">
            <span class="span1">Layer: </span>
            <span class="span2 spe-span"
              >{{ state.layer }} / {{ state.TotalLayer }}</span
            >
          </div>
          <div
            class="innerFlie"
            v-if="state.printStatus === 1 || state.printStatus === 5"
          >
            <span class="span1"></span>
            <div class="progress">
              <el-progress :percentage="state.printProgress" />
            </div>
          </div>
        </div>
      </div>
      <!-- 边界线 -->
      <div class="line_right"></div>
      <!-- 尾部数据信息 -->
      <div class="footerContiner">
        <ul class="innerConul">
          <li v-for="(item, index) in state.moreInfo" :key="index">
            <!-- <i
            :class="['iconfont', item.iconShowInfo]"
            :style="{ color: item.iconColor, fontSize: item.fontSize }"
          ></i> -->
            <div class="rightInnerli">
              <span :style="{ marginLeft: item.marginLeft }">{{
                item.showInfo
              }}</span>
              <span>{{ item.resultInfo }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, watch, getCurrentInstance, onMounted, ref } from "vue";
import HeaderBar from "@/components/HeaderBar";
import { useMainStore } from "@/stores";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
const store = useMainStore();
const formatSecond = (s) => {
  let str = "";
  if (s > 3600) {
    str =
      parseInt(s / 3600) +
      "h " +
      parseInt((s % 3600) / 60) +
      "m " +
      parseInt((s % 3600) % 60) +
      "s";
  } else if (s < 3600 && 60 < s) {
    str = `${parseInt(s / 60)}m ${s % 60}s`;
  } else if (s < 60) {
    str = `${s}s`;
  }
  return str;
};
// 唤醒国际化
const { $t } = getCurrentInstance().proxy;
let state = reactive({
  controlFlag: true,
  standInfo: $t("printStatus.pause"),
  startInfo: $t("printStatus.goOn"),
  stopInfo: $t("printStatus.off"),
  gcodePriview: "",
  // 当前层数
  layer: 0,
  // 当前总层数
  TotalLayer: 0,
  printStatus: -1,
  statusArr: [
    "Printing stopped",
    "Printing",
    "printing complete",
    "Printing failed",
    "print abort",
    "Printing Paused",
  ],
  statusArr1: [
    "打印已停止",
    "局域网打印中",
    "打印完成",
    "打印失败",
    "打印中止",
    "打印暂停",
  ],
  statusTitle: "",
  fileName: "",
  socket: null,
  // 折叠面板
  foldFlag: true,
  ip: "",
  moreInfo: [
    {
      showInfo: $t("printStatus.printTime"),
      resultInfo: "",
      iconShowInfo: "icon-a-xingzhuang14",
      iconColor: "#02a0f9",
      type: "printJobTime",
      fontSize: "13px",
      marginLeft: "9.3px",
    },
    {
      showInfo: $t("printStatus.lastTime"),
      resultInfo: "",
      iconShowInfo: "icon-a-xingzhuang1628",
      iconColor: "#46BC5B",
      type: "printLeftTime",
      fontSize: "14px",
      marginLeft: "10px",
    },
  ],
});
// 监听语言切换,动态整理国际化
watch(
  () => locale.value,
  () => {
    // 后台动深层态数据手动国际化
    console.log("status", locale.value);
    // 打印时间
    state.moreInfo[0].showInfo = $t("printStatus.printTime");
    state.moreInfo[1].showInfo = $t("printStatus.lastTime");
    if (locale.value == "en") {
      console.log("check EN", state.statusArr[state.printStatus]);
      // 头部打印状态
      state.statusTitle = state.statusArr[state.printStatus];
    }
    if (locale.value == "zh") {
      console.log("check ZH", state.statusArr1[state.printStatus]);
      // 头部打印状态
      state.statusTitle = state.statusArr1[state.printStatus];
    }
  }
);
watch(
  [
    () => store.state,
    () => store.printProgress,
    () => store.printLeftTime,
    () => store.printJobTime,
    () => store.realTimeSpeed,
    () => store.realTimeFlow,
    () => store.usedMaterialLength,
    () => store.printFileName,
    // 当前层数
    () => store.layer,
    // 当前总层数
    () => store.TotalLayer,
  ],
  () => {
    const { state: status, printProgress } = store;
    state.printStatus = status;
    // console.log("什么状态", status);
    // 初始挂载打印状态
    if (locale.value == "en") {
      console.log("check EN", state.statusArr[state.printStatus]);
      // 头部打印状态
      state.statusTitle = state.statusArr[state.printStatus];
    }
    state.printProgress = printProgress;
    // 当前层数以及总层数的数据挂载
    state.layer = store.layer;
    state.TotalLayer = store.TotalLayer;
    state.fileName = store.printFileName
      ? store.printFileName.substring(store.printFileName.lastIndexOf("/") + 1)
      : "";
    for (const item of state.moreInfo) {
      switch (item.type) {
        case "printJobTime":
          item.resultInfo = formatSecond(store.printJobTime);
          break;
        case "printLeftTime":
          item.resultInfo = formatSecond(store.printLeftTime);
          break;
        case "realTimeSpeed":
          item.resultInfo = store.realTimeSpeed + "mm/s";
          break;
        case "realTimeFlow":
          item.resultInfo = store.realTimeFlow + "mm³/s";
          break;
        case "usedMaterialLength":
          item.resultInfo = store.usedMaterialLength + "mm";
          break;
      }
    }
  },
  {
    immediate: true,
  }
);
//fetch校验资源有效性
const checkResourceValidity = async function (url) {
  return fetch(url, { method: "HEAD" })
    .then((response) => {
      if (response.ok) {
        console.log("资源是有效的");
        state.effective = true;
        return true;
      } else {
        console.log("资源是无效的");
        state.effective = false;
        const myImage = require("../../../assets/img/default01.png");
        state.gcodePriview = myImage;
        return false;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      return false;
    });
};
watch(
  () => state.printStatus,
  () => {
    state.gcodePriview = `http://${
      state.ip
    }:80/downloads/original/current_print_image.png
?date=${new Date()}`;
    checkResourceValidity(state.gcodePriview);
  }
);
const showContinue = ref(false);
const curContinueVal = ref("");
const continueTip = ref("");
watch(
  [() => store.materialStatus, () => store.repoPlrStatus, () => locale.value],
  () => {
    showContinue.value = !!store.materialStatus || !!store.repoPlrStatus;
    if (store.materialStatus) {
      curContinueVal.value = "materialStatus";
      continueTip.value = $t("printStatus.notTestMaterial");
    }
    if (store.repoPlrStatus) {
      curContinueVal.value = "repoPlrStatus";
      continueTip.value = $t("printStatus.stopUnexpect");
    }
  }
);

const setContinue = () => {
  if (curContinueVal.value === "materialStatus") {
    state.socket.sendMsg({
      method: "set",
      params: {
        pause: 0,
      },
    });
  } else {
    state.socket.sendMsg({
      method: "set",
      params: {
        repoPlrStatus: 1,
      },
    });
  }
};

const setCancelContinue = () => {
  if (curContinueVal.value === "materialStatus") {
    state.socket.sendMsg({
      method: "set",
      params: {
        stop: 0,
      },
    });
  } else {
    state.socket.sendMsg({
      method: "set",
      params: {
        repoPlrStatus: 0,
      },
    });
  }
};

const start = () => {
  state.socket.sendMsg({
    method: "set",
    params: {
      pause: +state.printStatus === 1 ? 1 : 0,
    },
  });
};
const stop = () => {
  state.socket.sendMsg({
    method: "set",
    params: {
      stop: 1,
    },
  });
};
// 折叠面板
const foldThePage = () => {
  // console.log("准备缩小面板啦");
  state.foldFlag = false;
};
// 展开面板
const openThePage = () => {
  // console.log("准备展开面板啦");
  state.foldFlag = true;
};
onMounted(() => {
  const { proxy } = getCurrentInstance();
  state.socket = proxy.$webSocket;
  state.ip = proxy.$ip;
  // 水波进度条JS控制方法
  var canvas = document.getElementById("c");
  var ctx = canvas.getContext("2d");
  //range控件信息
  var rangeValue = 1;
  var nowRange = 0; //用于做一个临时的range
  //画布属性
  var mW = (canvas.width = 250);
  var mH = (canvas.height = 250);
  var lineWidth = 2;
  //Sin 曲线属性
  var sX = 0;
  var axisLength = mW; //轴长
  var waveWidth = 0.015; //波浪宽度,数越小越宽
  var waveHeight = 6; //波浪高度,数越大越高
  var speed = 0.09; //波浪速度，数越大速度越快
  var xOffset = 0; //波浪x偏移量
  ctx.lineWidth = lineWidth;
  //画sin 曲线函数
  var drawSin = function (xOffset) {
    ctx.save();
    //用于存放绘制Sin曲线的点
    var points = [];
    ctx.beginPath();
    //在整个轴长上取点
    for (var x = sX; x < sX + axisLength; x += 20 / axisLength) {
      //此处坐标(x,y)的取点，依靠公式 “振幅高*sin(x*振幅宽 + 振幅偏移量)”
      var y = -Math.sin((sX + x) * waveWidth + xOffset);
      var dY = mH * (1 - nowRange / 100);
      points.push([x, dY + y * waveHeight]);
      ctx.lineTo(x, dY + y * waveHeight);
    }
    //封闭路径
    ctx.lineTo(axisLength, mH);
    ctx.lineTo(sX, mH);
    ctx.lineTo(points[0][0], points[0][1]);
    ctx.fillStyle = "#1E9BE2";
    ctx.fill();
    ctx.restore();
  };
  // console.log("gcode预览图是多少", state.gcodePriview);
  var render = function () {
    ctx.clearRect(0, 0, mW, mH);
    rangeValue = state.printProgress;
    if (+rangeValue === 0) {
      nowRange = 0;
    }
    if (nowRange <= rangeValue) {
      var tmp = 1;
      nowRange += tmp;
    }
    if (nowRange > rangeValue) {
      nowRange -= tmp;
    }
    drawSin(xOffset);
    xOffset += speed;
    requestAnimationFrame(render);
  };
  render();
});
</script>
<style lang="scss" scoped>
.controlAnimal {
  height: 40px !important;
}
.control-main {
  width: 100%;
  height: 218px;
  @include theme_layout();
  .fold {
    cursor: pointer;
    .iconfont {
      color: #d2d2da;
      font-size: 8px;
    }
  }
  // 兼容移动设备
  @media screen and (max-width: 1200px) {
    .right-func {
      .start-func {
        margin-right: 1rem !important;
        button {
          width: 3.4rem !important;
          height: 24px;
          border-radius: 12px;
          font-size: 12px;
          &:first-child {
            margin-right: 0rem !important;
          }
          .iconfont {
            margin-right: 5px;
            font-size: 12px;
            display: none;
          }
        }
      }
      .continue-func {
        padding: 0 !important;
        border: none !important;
        margin-right: 0 !important;
        .el-button + .el-button {
          margin-left: 0.3rem !important;
        }
        i {
          cursor: pointer;
          margin-right: 0.3rem !important;
        }
      }
    }
  }
  .right-func {
    display: flex;
    align-items: center;
    justify-content: center;
    .continue-func {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      border-radius: 10px;
      border: 1px solid #4f4f54;
      margin-right: 10px;
      .el-button {
        border-radius: 10px;
      }
      i {
        cursor: pointer;
        margin-right: 10px;
      }
    }
    .start-func {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      button {
        width: 100px;
        height: 24px;
        border-radius: 12px;
        font-size: 12px;
        &:first-child {
          margin-right: 10px;
        }
        .iconfont {
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }
  }
  // .right-feature {
  //   width: 15rem;
  //   padding: 8px 0px;
  //   display: flex;
  //   justify-content: space-evenly;

  //   button {
  //     width: 100px;
  //     height: 24px;
  //     border-radius: 12px;
  //     font-size: 12px;
  //     .iconfont {
  //       margin-right: 5px;
  //       font-size: 12px;
  //     }
  //   }
  // }
  .containMain {
    @media screen and (max-width: 1200px) {
      .middleContent {
        width: 92% !important;
      }
    }
    .middleContent {
      width: 62%;
      height: 155px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 11px 0px 0px 20px;
      // 兼容移动设备
      @media screen and (max-width: 1200px) {
        .leftPrintshow {
          width: 135px !important;
          img {
            width: 134px !important;
            margin-top: 0 !important;
          }
          #c {
            width: 134px !important;
            left: calc(5% + 20px) !important;
          }
        }
      }
      .leftPrintshow {
        width: 230px;
        height: 135px;
        background: #2b2b2f;
        border-radius: 5px;
        img {
          // width: fit-content !important;
          margin-top: 1rem;
        }
        #c {
          opacity: 0.3;
          width: 10.4%;
          height: 135px;
          position: absolute;
          left: 41px;
        }
      }
      // 兼容移动设备
      @media screen and (max-width: 1200px) {
        .rightPrintshow {
          padding: 0px 0px 0px 15px !important;
          width: 100% !important;
          margin-top: 80px !important;
          .innerFlie {
            height: 25px !important;
            .span2 {
              width: 5rem !important;
            }
          }
        }
      }
      .rightPrintshow {
        width: 400px;
        height: 100px;
        text-align: left;
        padding: 5px 0px 0px 20px;
        .innerFlie {
          width: 100%;
          .span1 {
            width: 24px;
            height: 10px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #7f7f80;
            display: inline-block;
            vertical-align: text-top;
          }
          .span2 {
            width: 8rem;
            display: inline-block;
            height: 18px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #cbcbcc;
            vertical-align: text-bottom;
            padding: 14px 0px 0px 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .spe-span {
            text-align: left;
          }
          .progress {
            :deep() .el-progress-bar__outer {
              height: 3px !important;
            }
            :deep() .el-progress__text {
              font-size: 16px !important;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
              padding-left: 5px;
            }
          }
        }
      }
    }
    .line_right {
      width: 1px;
      height: 100px;
      background: #414145;
      position: absolute;
      top: 10rem;
      left: 33%;
    }
    // 兼容移动设备
    @media screen and (max-width: 1200px) {
      .footerContiner {
        width: 49% !important;
        left: calc(5% + 158px) !important;
        top: 8rem !important;
      }
      .line_right {
        display: none;
      }
    }
    .footerContiner {
      width: 194px;
      text-align: left;
      position: absolute;
      left: 35%;
      top: 10rem;

      .innerConul {
        li {
          display: flex;
          padding-top: 14px;
          .rightInnerli {
            width: 86%;
            display: flex;
            justify-content: space-between;
            span:nth-child(1) {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #7f7f80;
              display: inline-block;
            }
            span:nth-child(2) {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #cbcbcc;
              //padding-left: 50px;
              width: 74px;
              text-align: left;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
</style>
