export default {
    23: (v, locale)=>  locale("errorMessage.printNoReady"),
    2218: (v, locale)=> locale("errorMessage.chuanganqi"),
    2219: (v, locale)=> locale("errorMessage.paramsNOuse"),
    2220: (v, locale)=> locale("errorMessage.weizhizu") + `${v[0]}`,
    2221: (v, locale)=> locale("errorMessage.wuxiaozi") + `${v[0]}`,
    2222: (v, locale)=> locale("errorMessage.zixing") + `${v[0]}` + locale("errorMessage.buzhengque"),
    2223: (v, locale)=> locale("errorMessage.bufenmingc") + `${v[0]}` + locale("errorMessage.wuxiao"),
    2224: (v, locale)=> locale("errorMessage.yinjiao"),
    2225: (v, locale)=> locale("errorMessage.chouxiang"),
    2226: (v, locale)=> locale("errorMessage.caidanxiang") + `${v[0]}` + locale("errorMessage.bujieshou"),
    2227: (v, locale)=> locale("errorMessage.wrongType"),
    2228: (v, locale)=> locale("errorMessage.select") + `${v[0]}`+ locale("errorMessage.myChose") + `${v[1]}` + locale("errorMessage.notUseSelect"),
    2229: (v, locale)=> locale("errorMessage.weizhicai") + `${v[0]}。`,
    2230: (v, locale)=> locale("errorMessage.wujiexi"),
    2231: (v, locale)=> `${v[0]}` + locale("errorMessage.tongMcu"),
    2232: (v, locale)=> locale("errorMessage.ceshizhi"),
    2233: (v, locale)=> locale("errorMessage.wanggewai") + `${v[0]}`+  + `${v[1]}` + locale("errorMessage.fade_target") + `${v[2]}`,
    2234: (v, locale)=> locale("errorMessage.wangge2") + `${v[0]}` +  locale("errorMessage.wanggexiao") + `${v[1]}` + locale("errorMessage.wanggezuida") + `${v[2]}`,
    2235: (v, locale)=> locale("errorMessage.wanggeshuiping"),
    2236: (v, locale)=> locale("errorMessage.dicengtan"),
    2237: (v, locale)=> locale("errorMessage.meijiazai"),
    2238: (v, locale)=> locale("errorMessage.shengzuobiao"),
    2239: (v, locale)=> locale("errorMessage.sancicacao") + `${v[0]}, ${v[1]}`,
    2240: (v, locale)=> locale("errorMessage.bupipei") + `${v[0]}, ${v[1]}` + locale("errorMessage.probed") + `(${v[3]}, ${v[4]})`,
    2241: (v, locale)=> locale("errorMessage.ycontrol"),
    2242: (v, locale)=> locale("errorMessage.guiling") + `${v[0]} ${v[1]} ${v[2]} [${v[3]}]`,
    2243: (v, locale)=> locale("errorMessage.chaofanwei") + `${v[0]} ${v[1]} ${v[2]} [${v[3]}]`,
    2244: (v, locale)=> locale("errorMessage.yidongchang") + `${v[0]}mm vs${v[1]}mm）` + locale("errorMessage.xiangxinxi"),
    2246: (v, locale)=> locale("errorMessage.jiemianxuanx") + `${v[0]}` + locale("errorMessage.bixuyou") + `${v[1]}`,
    2247: (v, locale)=> locale("errorMessage.jiemianxuanx") + `${v[0]}` + locale("errorMessage.dezuida") + `${v[1]}`,
    2248: (v, locale)=> locale("errorMessage.fenxican") + `${v[0]}`,
    2249: (v, locale)=> locale("errorMessage.canshu") + `${v[0]}` + locale("errorMessage.dezuixiaob") +`${v[1]}`,
    2250: (v, locale)=> locale("errorMessage.canshu") + `${v[0]}` + locale("errorMessage.dezuidab") +`${v[1]}`,
    2251: (v, locale)=> `${v[0]}` + locale("errorMessage.shangcuo1") + `${v[1]}`,
    2252: (v, locale)=> `${v[0]}` + locale("errorMessage.shangcuo2") + `${v[1]}` + locale("errorMessage.zuixiaozhi") +`${v[2]}`,
    2253: (v, locale)=> `${v[0]}` + locale("errorMessage.shangcuo2") + `${v[1]}` + locale("errorMessage.zuixiaozhi") +`${v[2]}`,
    2254: (v, locale)=> `${v[0]}` + locale("errorMessage.shangcuo") + `${v[1]}` + locale("errorMessage.bidayu") +`${v[2]}`,
    2255: (v, locale)=> `${v[0]}` + locale("errorMessage.shangcuo") + `${v[1]}` + locale("errorMessage.bixiaoyu") +`${v[2]}`,
    2256: (v, locale)=> locale("errorMessage.weizhimeib"),
    2257: (v, locale)=> locale("errorMessage.tanshibai"),
    2258: (v, locale)=> locale("errorMessage.xunidian"),
    2259: (v, locale)=> locale("errorMessage.duanzhidian"),
    2260: (v, locale)=> locale("errorMessage.yidongpenzui"),
    2261: (v, locale)=> locale("errorMessage.deltadayin"),
    2262: (v, locale)=> locale("errorMessage.celiangzhi"),
    2263: (v, locale)=> locale("errorMessage.jibenjioazhun"),
    2264: (v, locale)=> locale("errorMessage.canshu") + `${v[0]}` + locale("errorMessage.bixuyou1") +`${v[1]}`+ locale("errorMessage.gezhi"),
    2265: (v, locale)=> locale("errorMessage.jiaozhunW"),
    2266: (v, locale)=> locale("errorMessage.dostaryun"),
    2267: (v, locale)=> locale("errorMessage.tongjixin") + `${v[0]}`,
    2268: (v, locale)=> locale("errorMessage.bujinqi"),
    2269: (v, locale)=> locale("errorMessage.weizhiHong") +`${v[0]}`,
    2270: (v, locale)=> locale("errorMessage.wufajiang") + `${v[0]}` + locale("errorMessage.wenzixi"),
    2271: (v, locale)=> locale("errorMessage.zhidingbian"),
    2272: (v, locale)=> `${v[0]}` + locale("errorMessage.zhongsuwuxiao"),
    2273: (v, locale)=> locale("errorMessage.wufafenxi") + `${v[0]}`,
    2274: (v, locale)=> locale("errorMessage.weizhig") + `${v[0]}`,
    2275: (v, locale)=> locale("errorMessage.fenwuxiao") + `${v[0]}`,
    2276: (v, locale)=> locale("errorMessage.replicape"),
    2277: (v, locale)=> locale("errorMessage.jingtaiyin"),
    2278: (v, locale)=> locale("errorMessage.fanzhuanyin"),
    2279: (v, locale)=> locale("errorMessage.pwnxin"),
    2280: (v, locale)=> locale("errorMessage.weizhipin") + `${v[0]}`,
    2281: (v, locale)=> locale("errorMessage.offetxiao") + `${v[0]}` + locale("errorMessage.jiuzheng"),
    2282: (v, locale)=> locale("errorMessage.wufenxi") + `${v[0]},${v[1]}`,
    2283: (v, locale)=> locale("errorMessage.weizhunbei"),
    2284: (v, locale)=> locale("errorMessage.bianliangwen"),
    2285: (v, locale)=> locale("errorMessage.bianliangwen") + `${v[0]}`,
    2286: (v, locale)=> locale("errorMessage.baobianl"),
    2287: (v, locale)=> locale("errorMessage.mcngui"),
    2288: (v, locale)=> locale("errorMessage.jingyin"),
    2289: (v, locale)=> locale("errorMessage.guanjizhi"),
    2290: (v, locale)=> locale("errorMessage.chishida"),
    2291: (v, locale)=> locale("errorMessage.ruanpwn"),
    2292: (v, locale)=> locale("errorMessage.zhouqida"),
    2293: (v, locale)=> locale("errorMessage.zhouqidad"),
    2294: (v, locale)=> locale("errorMessage.wait") + `${v[0]}`+locale("errorMessage.xiangyingcao"),
    2295: (v, locale)=> locale("errorMessage.chongqimcm") + `${v[0]}` + locale("errorMessage.fail"),
    2296: (v, locale)=> locale("errorMessage.mcu") + `${v[0]}` + locale("errorMessage.CRCpei"),
    2297: (v, locale)=> locale("errorMessage.cajiao") + `${v[0]}` + locale("errorMessage.feiMcu")+ `${v[1]}` + locale("errorMessage.chajiaom"),
    2298: (v, locale)=> locale("errorMessage.gengMcn") + `${v[0]}` + locale("errorMessage.peizhi"),
    2299: (v, locale)=> locale("errorMessage.wupeiM") + `${v[0]}`,
    300: (v, locale)=> locale("errorMessage.peiqiM") + `${v[0]}` + locale("errorMessage.chucuo1") + `${v[1]}`,
    2301: (v, locale)=> locale("errorMessage.zipeishi") + `${v[0]}`,
    2302: (v, locale)=> locale("errorMessage.keyishao") + `${v[0]}`,
    2303: (v, locale)=> locale("errorMessage.buzhiyin") + `${v[0]}`,
    2304: (v, locale)=> `${v[0]}` + locale("errorMessage.zhougewu"),
    2305: (v, locale)=> locale("errorMessage.wufenzhou") +`${v[0]}`,
    2306: (v, locale)=> locale("errorMessage.buzhishu") + `${v[0]}` + locale("errorMessage.jinreson"),
    2307: (v, locale)=> locale("errorMessage.weizhishu"),
    2308: (v, locale)=> `${v[0]}` + locale("errorMessage.zhoujiashu"),
    2309: (v, locale)=> locale("errorMessage.xiangleiy") + `${v[0]}` + locale("errorMessage.wuxiaob"),
    2310: (v, locale)=> locale("errorMessage.bixuz") + `${v[0]}` + locale("errorMessage.shangzhid") + `${v[1]}`,
    2311: (v, locale)=> locale("errorMessage.weizhi") + `${v[0]} '${v[1]}'`,
    2312: (v, locale)=> locale("errorMessage.yuanjisuan") + `${v[0]}`,
    2313: (v, locale)=> locale("errorMessage.chujiashu") + `${v[0]}` + locale("errorMessage.shineicuo"),
    2314: (v, locale)=> locale("errorMessage.xiewenj") + `${v[0]}` + locale("errorMessage.shicucuo") + `${v[1]}`,
    2315: (v, locale)=> locale("errorMessage.pingst") + `${v[0]}` + locale("errorMessage.tiaomug"),
    2316: (v, locale)=> locale("errorMessage.lengduanfan"),
    2317: (v, locale)=> locale("errorMessage.reoufan"),
    2318: (v, locale)=> locale("errorMessage.lengduangao"),
    2319: (v, locale)=> locale("errorMessage.lengduandi"),
    2320: (v, locale)=> locale("errorMessage.reougao"),
    2321: (v, locale)=> locale("errorMessage.reouDi"),
    2322: (v, locale)=> locale("errorMessage.guodianya"),
    2323: (v, locale)=> locale("errorMessage.reoukailu"),
    2324: (v, locale)=> locale("errorMessage.kailu"),
    2325: (v, locale)=> locale("errorMessage.duididuan"),
    2326: (v, locale)=> locale("errorMessage.vcduan"),
    2327: (v, locale)=> locale("errorMessage.sheIDw"),
    2328: (v, locale)=> locale("errorMessage.redianoukai"),
    2329: (v, locale)=> locale("errorMessage.shuruduan"),
    2330: (v, locale)=> locale("errorMessage.rtdduanlu"),
    2331: (v, locale)=> locale("errorMessage.daqiagkai"),
    2332: (v, locale)=> locale("errorMessage.xiaoqiangkai"),
    2333: (v, locale)=> locale("errorMessage.xiaoqiangkai"),
    2334: (v, locale)=> locale("errorMessage.weizhid"),
    2335: (v, locale)=> locale("errorMessage.xuanxiang") + `${v[0]}` + locale("errorMessage.zaijie") + `${v[1]}` + locale("errorMessage.bixudi"),
    2336: (v, locale)=> locale("errorMessage.baojietou") + `\n${v[0]}` + locale("errorMessage.jiuzhengwen"),
    2337: (v, locale)=> locale("errorMessage.wenjianjie") + `${v[0]}` + locale("errorMessage.chongjiapei"),
    3000: (v, locale)=> `Shutdown due to webhooks request
    Once the underlying issue is corrected, use the
    "FIRMWARE_RESTART" command to reset the firmware, reload the
    config, and restart the host software.
    Printer is shutdown`,

    1: (v, locale)=> `key${v[0]}` + locale("errorMessage.dianjiyi"),
    2:(v, locale)=> `key${v[0]}` + locale("errorMessage.neibvucuo"),
    3: (v, locale)=>`key${v[0]}` + locale("errorMessage.tongxunyi"),
    4: (v, locale)=>`key${v[0]}` + locale("errorMessage.yuqijia"),
    5: (v, locale)=>`key${v[0]}` + locale("errorMessage.rechuangyi"),
    6: (v, locale)=>`key${v[0]}` + locale("errorMessage.jichuyi"),
    7: (v, locale)=>`key${v[0]}` + locale("errorMessage.dayinyi"),
    8: (v, locale)=>`key${v[0]}` + locale("errorMessage.dayinzhi"),
    200: (v, locale)=>`key${v[0]}` + locale("errorMessage.weizhicuo"),
    201: (v, locale)=>`key${v[0]}` + locale("errorMessage.wenqiangyi"),
    202: (v, locale)=>`key${v[0]}` + locale("errorMessage.dayinwenyi"),
    203: (v, locale)=>`key${v[0]}` + locale("errorMessage.zhenwenyou"),
    204: (v, locale)=>`key${v[0]}` + locale("errorMessage.fenshanyi"),
    205: (v, locale)=>`key${v[0]}` + locale("errorMessage.wangluoyi"),
    206: (v, locale)=>`key${v[0]}` + locale("errorMessage.dayinzhi1"),
    207: (v, locale)=>`key${v[0]}` + locale("errorMessage.ztouchyi"),
    208: (v, locale)=>`key${v[0]}` + locale("errorMessage.wenfuyi"),
    500: (v, locale)=>`key${v[0]}` + locale("errorMessage.weizhiyi"),
    800: (v, locale)=>`key${v[0]}` + locale("errorMessage.yidongcuo"),
    801: (v, locale)=>`key${v[0]}` + locale("errorMessage.dayinjiuyu"),

}
