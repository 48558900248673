<template>
  <div
    @focusin="hasFocus = true"
    @focusout="hasFocus = false"
    class="v-input-wrap"
  >
    <!--  -->
    <div
      class="v-input v-input--hide-details v-text-field v-text-field--enclosed v-text-field--outlined"
      :class="{
        'v-input--is-focused': hasFocus,
        'v-input--is-disabled': disabled,
        'theme--dark': true,
      }"
    >
      <div class="v-input__control">
        <div ref="input-slot" class="v-input__slot">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from "vue";
const props = defineProps(["disabled"]);
// 向父组件发送事件: 当前这个SVG图标是否被点击了。
const emits = defineEmits(["focus", "blur"]);
const hasFocus = ref(false);
// 监听某一个字段
watch(hasFocus, (newVal) => {
  if (newVal) {
    emits("focus");
  } else {
    emits("blur");
  }
});
</script>

<style lang="scss" scoped>
.v-input-wrap {
  width: 100%;
}
.v-input__control {
  height: 29.875rem;
  .v-input__slot {
    .partLines {
      position: absolute;
    }
  }
}
:deep(.v-input__slot) {
  padding: 0px !important;
  outline: none !important;
  cursor: default !important;
  height: 29.875rem;
  fieldset {
    top: 0px !important;
  }
}
</style>
