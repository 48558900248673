import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import echarts from "./utils/echarts";
// 配置国际化
import i18n from "./i18n/index";
// 动态插入iconfont
import("./utils/iconfont");
// 公共样式库
import "@/assets/scss/common.scss";
import "@/assets/scss/element.scss";
import { messageCenter } from "./socket/messageCenter";
import { MyWebSocket } from "./socket/webSocket";
const app = createApp(App);
let webSocket;
// 172.21.10.29 动态IP
// 172.23.209.132
// 172.23.215.49
// 172.23.210.126 K1
// 172.23.210.79 新机器
// 172.23.215.135 k1
// 172.23.215.110 // 有排除对象服务的IP
// 172.23.213.55  // K1Max 排除对象测试IP
// 172.23.214.156 // 暂停，停止打印机指令失效IP
// 172.23.209.247 // K1 排除对象居中问题
const ip =
  process.env.NODE_ENV === "development" ? "172.23.215.110" : location.hostname;
function reconnectWebSocket() {
  // 入口函数
  if (webSocket) {
    //防止多个websocket同时执行
    webSocket.clear();
    webSocket = null;
  }
  webSocket = new MyWebSocket(`ws://${ip}:9999`);
  webSocket.init();
}
reconnectWebSocket();
messageCenter.on("reconnect", () => {
  reconnectWebSocket();
});
// 使用国际化
app.use(i18n);
app.use(router);
app.use(createPinia());
app.use(ElementPlus);
app.use(echarts);
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$webSocket = webSocket;
app.config.globalProperties.$messageCenter = messageCenter;
// 当前项目运行下的IP地址
app.config.globalProperties.$ip = ip;
app.mount("#app");
