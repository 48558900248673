<template>
  <div class="comp-FileManage" :class="{ controlAnimal: !state.foldFlag }">
    <HeaderBar :icon="'&#xe623;'">
      <template v-slot:right>
        <!-- table切换 -->
        <div class="inner-con">
          <div
            v-for="(item, index) in state.moduleList"
            :class="['inner-top', { 'inner-bg': tabClick == item.idx }]"
            :key="index"
            @click="switchModule(item)"
          >
            <!-- <i :class="['iconfont', item.iconInfo]"></i> -->
            <span>{{ item.moduleName }}</span>
          </div>
        </div>
        <el-progress
          v-show="!!progress"
          class="progress"
          :percentage="progress"
        />
        <div class="btn">
          <div v-show="tabClick === 0" class="containsWarp">
            <el-upload
              :show-file-list="false"
              class="upload-demo"
              action
              :before-upload="beforeUpload"
              :http-request="handleUpload"
            >
              <el-button size="small"
                ><i class="iconfont" style="font-size: 12px">&#xe643;</i
                >{{ $t("FileManage.insert") }}</el-button
              >
            </el-upload>
          </div>
        </div>
        <!-- 收起面板 -->
        <div class="fold">
          <!-- 折叠 -->
          <i class="iconfont" @click.stop="foldThePage" v-if="state.foldFlag"
            >&#xe65a;</i
          >
          <!-- 展开 -->
          <i class="iconfont" @click.stop="openThePage" v-else>&#xe659;</i>
        </div>
      </template>
    </HeaderBar>
    <!-- 文件列表-->
    <div class="content" v-show="state.foldFlag && tabClick === 0">
      <el-table :data="state.tableData" style="width: 100%" ref="table">
        <el-table-column
          :label="$t('FileManage.fileName')"
          align="center"
          width="350"
        >
          <template #default="scope">
            <div
              style="display: flex; align-items: center"
              @click.stop="rightClick(scope.row, $event)"
            >
              <img
                :src="scope.row.thumb"
                v-if="scope.row.thumb"
                alt=""
                width="24"
                height="20"
              />
              <div class="fileName" :title="scope.row.name">
                {{ scope.row.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('FileManage.fileSize')"
          sortable
          :sort-method="(a, b) => +a.size - +b.size"
          align="center"
        >
          <template #default="scope">
            {{ formatSize(scope.row.size) }}</template
          >
        </el-table-column>
        <el-table-column
          prop="layer"
          :label="$t('FileManage.layerHeight')"
          sortable
          align="center"
          :sort-method="(a, b) => a.layer - b.layer"
        >
          <template #default="scope"> {{ scope.row.layer }}mm</template>
        </el-table-column>
        <el-table-column
          :label="$t('FileManage.time')"
          sortable
          width="200"
          align="center"
          :sort-method="(a, b) => a.time - b.time"
        >
          <template #default="scope">
            {{
              formatDateTime(+scope.row.time, "yyyy-MM-dd HH:mm:ss")
            }}</template
          >
        </el-table-column>
        <el-table-column
          :label="$t('FileManage.hcLehgth')"
          sortable
          align="center"
          width="130"
          :sort-method="(a, b) => a.length - b.length"
        >
          <template #default="scope"> {{ scope.row.length }}m</template>
        </el-table-column>
        <!-- <el-table-column prop="path" label="耗材重量" sortable align="center" /> -->
      </el-table>
    </div>
    <div
      class="menu"
      v-show="menuShow"
      :style="{ left: menuLeft + 'px', top: menuTop + 'px' }"
    >
      <div
        v-for="(item, index) in state.menus"
        :key="index"
        @click.stop="menuClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      class="tablistCon"
      :class="{
        controlAnimal: !state.foldFlag,
        'show-style': tabClick === 0,
      }"
    >
      <div class="tableList" v-if="tabClick === 1">
        <el-table
          :data="state.hisTableData"
          style="width: 100%"
          @row-click="hisClick"
          @selection-change="handleSelectionChange"
          :row-class-name="rowSelClass"
          ref="multipleTableRef"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="filename"
            :label="$t('FileManage.files')"
            width="150"
            align="center"
          >
            <template #default="scope">{{
              scope.row.filename.split("/")[
                scope.row.filename.split("/").length - 1
              ]
            }}</template>
          </el-table-column>
          <el-table-column
            :label="$t('FileManage.status')"
            sortable
            width="120"
            align="center"
          >
            <template #default="scope">{{
              scope.row.printfinish == 0
                ? $t("FileManage.noFinish")
                : $t("FileManage.finish")
            }}</template>
          </el-table-column>
          <el-table-column
            prop="starttime"
            :label="$t('FileManage.startTime')"
            width="160px"
            sortable
            align="center"
          >
            <template #default="scope">{{
              timestampToTime(scope.row.starttime)
            }}</template>
          </el-table-column>
          <el-table-column
            prop="ctime"
            :label="$t('FileManage.endTime')"
            width="160px"
            sortable
            align="center"
          >
            <template #default="scope">{{
              timestampToTime(scope.row.starttime + scope.row.usagetime)
            }}</template>
          </el-table-column>
          <el-table-column
            prop="usagetime"
            :label="$t('FileManage.allTime')"
            width="150"
            sortable
            align="center"
          >
            <template #default="scope">{{
              formatSeconds(scope.row.usagetime)
            }}</template>
          </el-table-column>
          <el-table-column
            prop="size"
            :label="$t('FileManage.size')"
            sortable
            align="center"
          >
            <template #default="scope">{{
              (scope.row.size / 1048576).toFixed(2) + "M"
            }}</template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script setup>
import HeaderBar from "@/components/HeaderBar";
import { onMounted, reactive, ref, getCurrentInstance, watch } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  formatSize,
  timestampToTime,
  formatSeconds,
  formatDateTime,
} from "@/utils/public";
import { useMainStore } from "@/stores";
import axios from "axios";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
const store = useMainStore();
// 唤醒国际化
const { $t } = getCurrentInstance().proxy;
// const { proxy } = getCurrentInstance();
// let url = `http://${proxy.$ip}:80/upload`;
const state = reactive({
  // X归位
  // xBtnshowFlag: 0,
  // // y归位
  // yBtnshowFlag: 0,
  // // z归位
  // zBtnshowFlag: 0,
  // 打印状态
  tableDataFlag: false,
  printStatus: 0,
  // 头部tab切换
  moduleList: [
    {
      moduleName: $t("FileManage.files"),
      idx: 0,
      moduleInfo: "fileMent",
      iconInfo: "icon-a-xingzhuang14",
    },
    {
      moduleName: $t("FileManage.historyRecord"),
      idx: 1,
      moduleInfo: "history",
      iconInfo: "icon-a-xingzhuang14",
    },
  ],
  // 百分比进度
  printProgress: 0,
  // 历史记录的表格数据
  hisTableData: [],
  // 折叠面板
  foldFlag: true,
  tableData: [],
  fileList: [],
  socket: null,
  fileUrl: "",
  ip: "",
  messageCenter: null,
  Gtimer: false,
  selectRow: [],
  fileMenus: [
    { name: $t("FileManage.startPrint"), operType: 1 },
    { name: $t("FileManage.deleteFile"), operType: 2 },
    { name: $t("FileManage.newName"), operType: 3 },
  ],
  hisMenus: [{ name: $t("FileManage.deleteFile"), operType: 2 }],
});

const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
  menuShow.value = false;
};
watch(multipleSelection, (val) => {
  state.selectRow = [];
  if (!state.hisTableData.length) return;
  for (const item of val) {
    state.selectRow.push(state.hisTableData.indexOf(item));
  }
});
const rowSelClass = ({ rowIndex }) => {
  if (state.selectRow.includes(rowIndex)) {
    return "sel ";
  }
};
const menuShow = ref(false);
const menuLeft = ref(0);
const menuTop = ref(0);
const rowValue = ref("");

const hisClick = (row, column, event) => {
  event.stopPropagation();
  const refDom = document.querySelector(".comp-FileManage");
  const { top, left } = refDom.getBoundingClientRect();
  const { clientX, clientY } = event;
  menuLeft.value = clientX - left;
  menuTop.value = clientY - top;
  if (multipleSelection.value.length) menuShow.value = true;
};

const rightClick = (row, event) => {
  console.log("menuTop.value", menuTop.value);
  event.preventDefault();
  const refDom = document.querySelector(".comp-FileManage");
  const { top, left } = refDom.getBoundingClientRect();
  const { clientX, clientY } = event;
  menuLeft.value = clientX - left;
  menuTop.value = clientY - top;
  menuShow.value = true;
  rowValue.value = row;
};
// 重命名文件
const renameFile = () => {
  ElMessageBox.prompt(
    $t("FileManage.inputFilename"),
    $t("FileManage.newName"),
    {
      confirmButtonText: $t("configuration.sureInfo"),
      cancelButtonText: $t("configuration.cancel"),
      inputValue: rowValue.value.name,
      inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
      inputErrorMessage: $t("configuration.fileNameNull"),
    }
  )
    .then(({ value }) => {
      const { name, path } = rowValue.value;
      state.socket.sendMsg({
        method: "set",
        params: {
          opGcodeFile: "renameprt:" + `${path}/${name}:${path}/${value}`,
        },
      });
      setTimeout(() => {
        ElMessage({
          type: "success",
          message: $t("FileManage.newNameScuess"),
        });
      }, 1700);
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: $t("FileManage.newNameCancel"),
      });
    });
};
const tabClick = ref(0);
const multipleTableRef = ref();

// 模块切换: 文件和历史记录
function switchModule(item) {
  tabClick.value = item.idx;
  menuShow.value = false;
  if (item.moduleInfo === "history") {
    state.menus = state.hisMenus;
    multipleTableRef.value && multipleTableRef.value.clearSelection();
    multipleSelection.value = [];
    state.socket.sendMsg({
      method: "get",
      params: { reqHistory: 1 },
    });
  } else {
    state.menus = state.fileMenus;
  }
}
const deleteHis = () => {
  const delArr = multipleSelection.value.map((m) => m.id);
  state.socket.sendMsg({
    method: "set",
    params: { deleteHistory: delArr },
  });
  multipleTableRef.value && multipleTableRef.value.clearSelection();
};
// 删除文件的服务
const deleteFile = () => {
  ElMessageBox.confirm(
    $t("configuration.sureDelete"),
    $t("configuration.deleteFile"),
    {
      confirmButtonText: $t("configuration.sureInfo"),
      cancelButtonText: $t("configuration.cancel"),
      type: "warning",
    }
  )
    .then(() => {
      const { name, path } = rowValue.value;
      state.socket.sendMsg({
        method: "set",
        params: {
          opGcodeFile: "deleteprt:" + path + "/" + name,
        },
      });
      setTimeout(() => {
        ElMessage({
          type: "success",
          message: $t("FileManage.deleteSuccess"),
        });
      }, 1700);
    })
    .catch((err) => {
      console.log("err", err);
      ElMessage({
        type: "info",
        message: $t("FileManage.calcelDelete"),
      });
    });
};
// 折叠面板
const foldThePage = () => {
  state.foldFlag = false;
};
// 展开面板
const openThePage = () => {
  state.foldFlag = true;
};
// 调用去打印方法: (前置校验, 如果机器未归位则优先去归位)
const printFile = () => {
  const { name, path } = rowValue.value;
  console.log("name", name);
  console.log("path", path);
  state.socket.sendMsg({
    method: "set",
    params: {
      opGcodeFile: "printprt:" + path + "/" + name,
    },
  });
};

// 上传之前校验
const beforeUpload = (file) => {
  const idx = file.name.lastIndexOf(".");
  const ext = file.name.substring(idx + 1);
  if (ext.toLowerCase() !== "gcode") {
    ElMessage.warning($t("FileManage.gcodeSilceTips"));
    return false;
  }
  if (file.name.toLowerCase() === store.printFileName.toLowerCase()) {
    ElMessage.warning($t("FileManage.samePrinting"));
    return false;
  }
  state.fileUrl = "/upload/" + file.name;
  return true;
};
const table = ref();
const progress = ref(0);
const handleUpload = (file) => {
  let formData = new FormData();
  formData.append("file", file.file);
  axios
    .post(state.fileUrl, formData, {
      onUploadProgress: (p) => {
        progress.value = Math.floor((p.loaded / p.total) * 100);
      },
    })
    .then((res) => {
      progress.value = 0;
      if (res.data.code === 200) {
        ElMessage.success($t("FileManage.insertSuccess"));
        state.socket.sendMsg({
          method: "get",
          params: {
            reqGcodeFile: 1,
          },
        });
      } else {
        ElMessage.warning($t("FileManage.uploadFailed"));
      }
    });
};

const menuClick = (item) => {
  menuShow.value = false;
  if (item.operType === 3) renameFile();
  if (item.operType === 2) {
    if (tabClick.value === 1) {
      deleteHis();
    } else {
      deleteFile();
    }
  }
  if (item.operType === 1) printFile();
};

const checkImgExists = (url, other) => {
  return new Promise((resolve) => {
    const obj = new Image();
    obj.src = url;
    obj.onload = () => resolve(url);
    obj.onerror = () => {
      const obj1 = new Image();
      obj1.src = other;
      obj1.onload = () => resolve(other);
      obj1.onerror = () => resolve("");
    };
  });
};
// 监听语言切换,动态整理国际化
watch(
  () => locale.value,
  () => {
    // 头部深层不刷新数据更新国际化
    state.moduleList[0].moduleName = $t("FileManage.files");
    state.moduleList[1].moduleName = $t("FileManage.historyRecord");
    // 开始打印小弹窗国际化
    state.fileMenus[0].name = $t("FileManage.startPrint");
    state.fileMenus[1].name = $t("FileManage.deleteFile");
    state.fileMenus[2].name = $t("FileManage.newName");
  }
);
// 监听gcode文件数据
watch(
  () => store.retGcodeFileInfo,
  async (val) => {
    console.log("数据更新啦", 6666);
    state.tableData = [];
    const listStr = val.fileInfo;
    const arr = listStr.split(";").filter((f) => !!f);
    let sortArr = [];
    for (const item of arr) {
      const v = item.split(":");
      const ip = `http://${state.ip}:80`;
      const humbName = v[6].substring(v[6].lastIndexOf("/") + 1);
      const url1 = `${ip}/downloads/humbnail/${humbName}`;
      const url2 = `${ip}/downloads/defData/${humbName}`;
      const res = await checkImgExists(url1, url2);
      sortArr.push({
        path: v[0],
        name: v[1],
        size: +v[2],
        layer: +v[3],
        time: v[4],
        length: +v[5] / 1000,
        thumb: res,
      });
    }
    state.tableData = sortArr.sort((a, b) => b.time - a.time);
    state.tableDataFlag = true;
  }
);
// 监听历史记录
watch(
  () => store.historyList,
  (historyList) => {
    if (!historyList || !historyList.length) return;
    state.hisTableData = JSON.parse(JSON.stringify(historyList));
  },
  { immediate: true }
);
onMounted(() => {
  const { proxy } = getCurrentInstance();
  state.socket = proxy.$webSocket;
  state.messageCenter = proxy.$messageCenter;
  state.ip = proxy.$ip;
  let timer1 = setInterval(() => {
    state.socket.sendMsg({
      method: "get",
      params: {
        reqGcodeFile: 1,
      },
    });
    if (state.tableDataFlag) {
      console.log("什么状态66666", state.tableDataFlag);
      clearInterval(timer1);
      timer1 = null;
    }
  }, 3000);

  // 右键移除弹窗
  window.oncontextmenu = (event) => {
    event.preventDefault();
    menuShow.value = false;
  };
  // 左键非目标也移除
  window.onclick = (e) => {
    let menu = document.querySelector(".menu");
    //判断当前点击的(e.target)是否在menu里面 如果不在就移除它
    if (menu && !menu.contains(e.target)) {
      menuShow.value = false;
    } //contains包含
  };
  // 刚卡是默认挂载文件列表(即时开始打印)
  state.menus = state.fileMenus;
  // 初始创建单次监听变量,配合节流处理。
});
</script>
<style lang="scss" scoped>
.controlAnimal {
  height: 40px !important;
}
::-webkit-scrollbar {
  width: 6px;
  // height: 10px;
  border-radius: 3px;
  background-color: #37373b;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #616169)
  );
  transition: 0.3s ease-in-out;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #37373b;
}
// 兼容移动设备
@media screen and (max-width: 1200px) {
  .comp-FileManage {
    :deep() .com-HeaderBar {
      // width: max-content !important;
      .upload-demo {
        padding-right: 0.5rem !important;
        .el-upload {
          .el-button {
            width: 4rem;
          }
        }
      }
    }
  }
}
.comp-FileManage {
  width: 100%;
  height: 411px;
  @include theme_layout();
  position: relative;
  .progress {
    width: 230px;
  }
  .inner-con {
    position: absolute;
    left: 50px;
    height: 33px;
    display: flex;
    align-items: center;
    // background: #37373b;
    // box-shadow: 0px 6px 6px 0px rgba(36, 36, 39, 0.5);
    border-radius: 5px;
    margin-bottom: 3px;
    .inner-top:hover {
      background: #525258;
      .iconfont {
        color: #fff;
      }
      span {
        color: #fff;
      }
    }
    .inner-top {
      width: 80px;
      height: 32px;
      border-radius: 5px;
      line-height: 32px;
      cursor: pointer;
      .iconfont {
        color: #cbcbcc;
      }
      span {
        color: #cbcbcc;
        margin-left: 7px;
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
    .inner-bg {
      background: #1e9be2;
      span {
        color: #ffffff;
      }
    }
    .inner-search {
      position: relative;
      margin-left: 54.9%;
      .el-input {
        width: 258px;
        height: 30px;
        font-size: 12px;
        padding-left: 20px;
        background: #37373b;
        border: 1px solid #4f4f54;
        border-radius: 15px;
      }
      .myself-icon {
        position: absolute;
        position: absolute;
        top: 7px;
        left: 13px;
        color: #cbcbcc;
      }
    }
    .inner-btn {
      padding-left: 5px;
      .el-button {
        width: 66px;
        height: 30px;
        font-size: 12px;
        box-shadow: 0px 3px 3px 0px #303034;
        border-radius: 15px;
      }
    }
  }
  .fold {
    cursor: pointer;
    .iconfont {
      color: #d2d2da;
      font-size: 8px;
    }
  }
  .btn {
    position: absolute;
    right: 10%;
    button {
      border-radius: 12px;
      padding: 0 20px;
      i {
        margin-right: 10px;
      }
    }
  }
  .content,
  .tablistCon {
    // padding: 20px;
    overflow: auto;
    .el-table {
      height: 320px;
      .fileName {
        position: absolute;
        left: 50px;
        width: 280px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        word-break: keep-all;
        text-overflow: ellipsis;
      }
    }
    .el-table:deep() .sel {
      --el-table-tr-bg-color: #46464a;
    }
  }
  .menu {
    position: absolute;
    width: 160px;
    background: #1e9be2;
    box-shadow: 0px 3px 3px 0px rgba(51, 51, 51, 0.5);
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    z-index: 9;
    > div {
      text-align: left;
      padding: 8px;
      cursor: pointer;
      &:first-child {
        border-radius: 5px 5px 0 0;
      }
      &:last-child {
        border-radius: 0 0 5px 5px;
      }
      &:hover {
        background: #a5dbf9;
      }
    }
  }
  .controlAnimal {
    height: 0px !important;
  }
  .show-style {
    height: 0px !important;
  }
}
</style>
