import { messageCenter } from "./messageCenter.js";
const ModeCode = {
  //websocket消息类型
  MSG: "message", //普通消息
  HEART_BEAT: "heart_beat", //心跳
};
// // 环境
// const env = process.env.NODE_ENV;
// // 项目IP
// const proIp = window.location.hostname;
// // WebSocket地址
// // 本地172
// const wsUrlLocal = "ws://172.21.10.97:9999";
// // 线上127
// const wsUrlProd = "ws://" + proIp + ":9999";
// console.log("websocket赋予动态IP", wsUrlProd);
// const wsUrl = env === "production" ? wsUrlProd : wsUrlLocal;
const heartBeatConfig = {
  time: 5 * 1000, // 心跳时间间隔
  timeout: 1 * 1000, // 心跳超时间隔
  reconnect: 5 * 1000, // 断线重连时间，一般的，断线重连时间等于心跳时间间隔加断线重连时间（忽略超时等待）
};
const isReconnect = true; // 是否重连
export class MyWebSocket extends WebSocket {
  constructor(url) {
    // 己This他的prototype,super.fun.call(this)，己This,看清哈
    super(url);
    return this;
  }

  /*
   * 入口函数
   * @param heartBeatConfig  time：心跳时间间隔 timeout：心跳超时间隔 reconnect：断线重连时间间隔
   * @param isReconnect 是否断线重连
   */
  init() {
    // WebSocket 那里截取的监听方法,直接拿来即用,赋值给某个方法,直接执行。
    this.onopen = this.openHandler; // 连接上时回调
    this.onclose = this.closeHandler; // 断开连接时回调
    this.onmessage = this.getMsg; // 收到服务端消息
    this.onerror = this.errorHandler; //连接出错
    this.heartBeat = heartBeatConfig;
    this.isReconnect = isReconnect;
    this.reconnectTimer = null; //断线重连时间器
    this.waitingTimer = null; // 超时等待时间器
    this.heartTimer = null; // 心跳时间器
    this.webSocketState = false; //socket状态 true为已连接
  }
  // 总部监听到的预定义的websocket服务正常,处于开启状态。
  openHandler() {
    this.webSocketState = true; //socket状态设置为连接，做为后面的断线重连的拦截器
    !!this.heartBeat &&
      !!this.heartBeat.time &&
      this.startHeartBeat(this.heartBeat.time); // 是否启动心跳机制
    // console.log("开启websocket服务,并启动心跳机制", this.readyState);
    messageCenter.emit("websocket_open", this.readyState);
  }
  // 服务正常连接,并从websocket服务桥接的后台服务中获取到数据
  getMsg(e) {
    let data = e.data;
    this.webSocketState = true;
    // console.log("websocket连接成功且收到消息:" + data);
    messageCenter.emit("message_change", data);

    // switch (data.ModeCode) {
    //   case ModeCode.MSG: // 普通消息
    //     console.log("收到消息" + data.msg);
    //     break;
    //   case ModeCode.HEART_BEAT: // 心跳
    //     console.log("收到心跳响应" + data.msg);
    //     break;
    // }
  }
  // 总部websocket服务检测到后台服务处于关闭状态。
  closeHandler() {
    //socket关闭
    //   messageCenter.emit("changeBtnState", "close"); //触发事件改变按钮样式
    this.webSocketState = false; //socket状态设置为断线
    // console.log("socket服务处于断连状态");
  }
  // 检测到websocket服务处于出错的状态: 未连接,或者后台服务端口无效,或不存在等
  errorHandler() {
    //socket出错
    this.webSocketState = false; //socket状态设置为断线
    this.reconnectWebSocket(); //重连
    console.log(
      "检测到websocket服务处于出错的状态: 未连接,或者后台服务端口无效,或不存在"
    );
  }
  // 向websocket 服务发送消息、指令等。
  sendMsg(obj) {
    // console.log("准备发送消息给socket服务: ", JSON.stringify(obj));
    try {
      this.send(JSON.stringify(obj));
    } catch (error) {
      messageCenter.emit("socket_close", "Failed");
    }
  }
  /*
   * 心跳初始函数
   * @param time：心跳时间间隔
   */
  startHeartBeat(time) {
    this.heartTimer = setTimeout(() => {
      this.sendMsg({
        ModeCode: ModeCode.HEART_BEAT,
        msg: new Date(),
      });
      this.waitingTimer = this.waitingServer();
      console.log("心跳间隔时间是多少: ", this.waitingTimer);
    }, time);
  }

  //延时等待服务端响应，通过webSocketState判断是否连线成功
  waitingServer() {
    this.webSocketState = false;
    return setTimeout(() => {
      if (this.webSocketState) return this.startHeartBeat(this.heartBeat.time);
      // 重连的罪魁祸首，就是这个玩意，我里个去
      // this.reconnectTimer = this.reconnectWebSocket();
    }, this.heartBeat.timeout);
  }

  //重连操作
  //重连操作
  reconnectWebSocket() {
    if (!this.isReconnect) return;
    return setTimeout(() => {
      messageCenter.emit("reconnect");
    }, this.heartBeat.reconnect);
  }
  // 清除所有定时器
  clearTimer() {
    clearTimeout(this.reconnectTimer);
    clearTimeout(this.heartTimer);
    // clearTimeout(this.waitingTimer);
  }
  // 关闭连接
  clear(isReconnect = false) {
    this.isReconnect = isReconnect;
    this.clearTimer();
    this.close();
    // console.log("关闭websocket服务");
  }
}
