<template>
  <div class="control-main" :class="{ controlAnimal: !state.foldFlag }">
    <!-- 头部容器 -->
    <HeaderBar :title="$t('wangchuang.name')" class="header" :icon="'&#xe661;'">
      <template v-slot:right>
        <!-- 收起面板 -->
        <div class="fold">
          <!-- 折叠 -->
          <i class="iconfont" @click.stop="foldThePage" v-if="state.foldFlag"
            >&#xe65a;</i
          >
          <!-- 展开 -->
          <i class="iconfont" @click.stop="openThePage" v-else>&#xe659;</i>
        </div>
      </template>
    </HeaderBar>
    <!-- 主体容器 -->
    <div class="middleContent">
      <!-- :class="{ active: state.checked === item.value }" -->
      <!-- <div class="leftPrintshow">
        <div
          class="btn_container"
          v-for="(item, index) in showButtonConfig"
          :key="index"
        >
          <el-button @click="handleFlat(item)">
            {{ item.label }}
          </el-button>
        </div>
      </div> -->
      <!-- 中间右侧容器 -->
      <div
        class="rightPrintshow"
        :style="{
          display: state.foldFlag ? 'block' : 'none',
        }"
      >
        <div class="content flat-manual" v-show="state.checked === 1">
          <div class="flat-block">
            <div class="row-btn">
              <el-button>1</el-button>
              <el-button>2</el-button>
            </div>
            <div><el-button>5</el-button></div>
            <div class="row-btn">
              <el-button>3</el-button>
              <el-button>4</el-button>
            </div>
          </div>
        </div>
        <!-- 画布容器 -->
        <div
          v-show="state.checked === null || state.checked === 2"
          class="content"
          ref="chartRef"
        >
          <div class="flat-auto"></div>
          <div class="auto-menu">
            <!-- <el-checkbox
              v-model="state.showBedMesh"
              label="显示网格线"
              size="large"
            /> -->
            <el-checkbox
              v-model="state.showBedFace"
              :label="$t('wangchuang.showBed')"
              size="large"
            />
            <el-button @click="removeProbedMatrix">
              {{ $t("wangchuang.delete") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted, getCurrentInstance, watch, ref } from "vue";
import HeaderBar from "@/components/HeaderBar";
import { useMainStore } from "@/stores";
const store = useMainStore();
let state = reactive({
  // 折叠面板
  foldFlag: true,
  levelCount: 60,
  showBedFace: false,
  showBedMesh: false,
  socket: null,
  echart: null,
  checked: 2,
});
// 折叠面板
const foldThePage = () => {
  // console.log("准备缩小面板啦");
  state.foldFlag = false;
};
// 展开面板
const openThePage = () => {
  // console.log("准备展开面板啦");
  state.foldFlag = true;
};
// // 中间input 配置
// const showButtonConfig = reactive([
//   // {
//   //   label: "龙门架调平",
//   //   value: 0,
//   // },
//   // {
//   //   label: "手动调平",
//   //   value: 1,
//   // },
//   {
//     label: "自动调平",
//     value: 2,
//   },
// ]);
let z0 = [];
const setOpt = () => {
  let fontColor = "rgba(255,255,255,0.25)";
  let lineColor = "#ffffff";
  const fontSize = 16;
  const opacity = 0.1;
  const visualMap = {
    itemWidth: 25,
    itemHeight: 280,
  };
  const axisCommon = {
    nameTextStyle: {
      color: fontColor,
    },
    axisPointer: {
      lineStyle: {
        color: lineColor,
        opacity: 0.65,
      },
      label: {
        margin: 16,
        color: fontColor,
        fontSize,
      },
    },
    axisTick: {
      lineStyle: {
        color: lineColor,
        opacity,
      },
    },
    axisLine: {
      lineStyle: {
        color: lineColor,
        opacity,
        width: 2,
      },
    },
    axisLabel: {
      textStyle: {
        color: fontColor,
        fontSize,
      },
    },
    splitLine: {
      lineStyle: {
        color: lineColor,
        opacity,
      },
    },
  };
  const points = probedMatrix.value;
  const data = [];
  let zMin = 0,
    zMax = 0,
    gap = 0.25;
  if (points.length) {
    for (var x = 0; x < points.length; x++) {
      if (points[x].z > zMax) zMax = points[x].z;
      if (points[x].z < zMin) zMin = points[x].z;
      data.push([points[x].x, points[x].y, points[x].z]);
      z0.push([points[x].x, points[x].y, 0]);
    }
  }
  let option = {
    tooltip: {
      textStyle: {
        align: "left",
        color: "#dbdbdc",
      },
      backgroundColor: "#515157",
      borderColor: "#515157",
      formatter: function (params) {
        let text = "";
        if (params.value && Array.isArray(params.value)) {
          text += `
        <div >
          <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>
          <div style="clear: both"></div>
          <span style="font-size:16px;font-weight:400;margin-left:2px">
            x: ${params.value[0]}
          </span>
          <div style="clear: both"></div>
          <span style="font-size:16px;font-weight:400;margin-left:2px">
            y: ${params.value[1]}
          </span>
          <div style="clear: both"></div>
          <span style="font-size:16px;font-weight:400;margin-left:2px">
            z: ${params.value[2]}
          </span>
          <div style="clear: both"></div>
        </div>
        `;
        }
        return text;
      },
    },
    visualMap: {
      type: "continuous",
      textStyle: {
        color: fontColor,
        fontSize,
      },
      realtime: true,
      calculable: true,
      show: true,
      top: 39,
      right: 35,
      dimension: 2,
      precision: 4,
      seriesIndex: 0,
      min: +zMin - gap,
      max: +zMax + gap,
      inRange: {
        color: [
          "#313695",
          "#4575b4",
          "#74add1",
          "#abd9e9",
          "#e0f3f8",
          "#ffffbf",
          "#fee090",
          "#fdae61",
          "#f46d43",
          "#d73027",
          "#a50026",
        ],
      },
      ...visualMap,
    },
    xAxis3D: {
      type: "value",
      ...axisCommon,
    },
    yAxis3D: {
      type: "value",
      ...axisCommon,
    },
    zAxis3D: {
      type: "value",
      interval: 0.5,
      max: 1,
      min: -1,
      ...axisCommon,
    },
    grid3D: {
      // show: true,
      // postEffect: {
      //   enable: true,
      //   SSAO: {
      //     enable: true,
      //     radius: 4,
      //   },
      // },
      // viewControl: {
      //   rotateSensitivity: 1.8,
      //   zoomSensitivity: 2,
      //   rotateMouseButton: "left",
      //   panMouseButton: "right",
      // },
    },
    series: [
      {
        name: "探测点矩阵",
        type: "surface",
        wireframe: {
          show: state.showBedMesh,
          lineStyle: {
            color: "#66AEFF",
          },
        },
        data,
      },
    ],
  };
  return option;
};

// const handleFlat = () => {
//   state.socket.sendMsg({
//     method: "set",
//     params: {
//       autoLevel: 5,
//     },
//   });
//   // state.checked = item.value;
// };

const renderMesh = () => {
  const chartDom = document.querySelector(".flat-auto");
  if (!chartDom) return;
  const e = state.echart.init(chartDom);
  z0 = [];
  let opt = JSON.parse(JSON.stringify(setOpt()));
  if (state.showBedFace) {
    opt.series.push({
      name: "参考平面",
      type: "surface",
      color: "rgba(128, 128, 128,0.25)",
      wireframe: {
        show: state.showBedMesh,
      },
      data: z0,
    });
  } else {
    opt.series = opt.series.filter((f) => f.name !== "参考平面");
  }
  e.clear();
  opt && e.setOption(opt);
  window.addEventListener("resize", () => e.resize());
};
// 获取调平数据
const probedMatrix = ref([]);
watch(
  () => store.probedMatrix,
  (val) => {
    // console.log("调平数据是什么", val);
    probedMatrix.value = val ? val.val : [];
    renderMesh(setOpt());
  }
);
watch([() => state.showBedMesh, () => state.showBedFace], () => {
  if (!probedMatrix.value.length) return;
  renderMesh();
});
// 清除网床数据
const removeProbedMatrix = () => {
  state.socket.sendMsg({
    method: "set",
    params: {
      rmProbedMatrix: 1,
    },
  });
};
onMounted(() => {
  const { proxy } = getCurrentInstance();
  state.socket = proxy.$webSocket;
  state.echart = proxy.$echarts;
  renderMesh(setOpt());
  state.socket.sendMsg({
    method: "get",
    params: {
      reqProbedMatrix: 1,
    },
  });
});
</script>
<style lang="scss" scoped>
.controlAnimal {
  height: 40px !important;
}
.control-main {
  // 不放首页
  // width: 58.8%;
  // 放首页
  width: 100%;
  // min-width: 500px;
  @include theme_layout();
  .fold {
    cursor: pointer;
    .iconfont {
      color: #d2d2da;
      font-size: 8px;
    }
  }
  .head-show {
    width: 81%;
    .left-feature {
      width: 54px;
      padding: 12px 15px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      span {
        padding-left: 5px;
      }
    }
    .right-feature {
      padding: 8px 15px;
      display: flex;
      justify-content: space-evenly;
      .head_inner {
        padding: 1% 0px 0px 1%;
        font-size: 14px;
      }
    }
  }
  .middleContent {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .active {
      background: #00a3ff;
    }
    .leftPrintshow {
      width: 19%;
      padding: 11px 0px 0px 20px;
      .btn_container {
        .el-button {
          width: 97%;
          height: 40px;
          box-shadow: 0px 3px 3px 0px rgba(31, 31, 35, 0.5);
          border-radius: 5px;
        }
        .el-button:nth-child(1) {
          margin-top: 10px;
        }
      }
    }
    .rightPrintshow {
      width: 96%;
      height: 500px;
      background: #2d2d31;
      border-radius: 5px;
      margin: 20px;
      position: relative;
      .flat-manual {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .flat-block {
        width: 310px;
        height: 310px;
        background: #2d2d31;
        border: 1px solid #46464a;
        border-radius: 5px;
        .row-btn {
          display: flex;
          justify-content: space-between;
        }
        .el-button {
          width: 103px;
          height: 103px;
          border-radius: 0px;
          font-size: 36px;
          color: #1e9be2;
          text-align: center;
          line-height: 103px;
        }
      }
      .content {
        width: 100%;
        height: 100%;
        position: relative;
      }
      .flat-auto {
        width: 100%;
        height: 100%;
      }
      .auto-menu {
        width: 160px;
        // height: 60px;
        // border: 1px solid;
        position: absolute;
        right: 2.5%;
        bottom: 5%;
        .el-checkbox {
          width: 160px;
          :deep().is-checked {
            .el-checkbox__inner {
              background: $theme;
            }
          }
          :deep().el-checkbox__label {
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .el-button {
          width: 160px;
        }
      }
    }
  }
}
</style>
