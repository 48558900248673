export default {
  // 通用配置
  configuration: {
    sureThat: "confirm",
    resetIt: "reboot",
    sureInfo: "Sure",
    cancel: "Cancel",
    closeWarn: "The websocket service is closed!",
    guiweiPrint: "Please reset the printer first!",
    allIt: "whole",
    assetDi: "The device is out of range!",
    crealityPrint: "Creality Print",
    resetFuwu: "Are you sure to restart the current service?",
    Tips: "Point Out",
    resetSuccess: "restart successfully!",
    quxiao: "Cancelled",
    paichuSuccess: "Exclusion succeeded!",
    surePaichu: "Whether to exclude selected models?",
    paichuObject: "Exclude object",
    paichuguol: "This object is excluded!",
  },
  // 文件管理国际化
  FileManage: {
    insert: "Import",
    files: "File",
    fileName: "File Name",
    fileSize: "File Size",
    layerHeight: "Storey",
    time: "Time",
    hcLehgth: "Consumable",
    name: "Name",
    status: "status",
    noFinish: "not finished",
    finish: "finished",
    startTime: "start time",
    endTime: "end time",
    allTime: "Total duration",
    size: "Size",
    historyRecord: "History",
    startPrint: "Start printing",
    deleteFile: "Delete File",
    deleteSuccess: "Delete succeeded!",
    calcelDelete: "Cancel Delete",
    newName: "rename",
    inputFilename: "Please enter a file name",
    fileNameNull: "File name cannot be empty!",
    newNameScuess: "Rename succeeded!",
    newNameCancel: "Rename Cancel",
    sureDelete: "Are you sure you want to delete this file?",
    homeTips:
      "Please return to the printer before starting the printing operation!",
    gcodeSilceTips: "Please import a Gcode slice file!",
    samePrinting: "The file is being printed, please import after printing",
    insertSuccess: "Import succeeded",
    uploadFailed: "Import failed",
  },
  // 运动控制国际化
  moveControl: {
    infoControl: "Control",
    printSpeed: "Printing speed: ",
    windSpeed: "wind speed",
    modelFengshan: "Model fan",
    qiangtiFengshan: "Back fan",
    fuzhuFengshan: "Side fan",
    lFengshan: "LED light",
    haomi: "millimetre",
    jichuDistence: "Extrusion distance",
    jichuSpeed: "Extrusion speed",
    haomiMiao: "Mm/sec",
    printWarning: "The printing status does not allow machine operation!",
    shuruMubiao: "Please re-enter the target value!",
  },
  //  打印状态管控
  printStatus: {
    pause: "Pause",
    goOn: "continue",
    off: "stop",
    cancel: "cancel",
    gcodePre: "Gcode preview image",
    printTime: "Printing time: ",
    lastTime: "Remaining: ",
    printPause: "Printing stopped",
    printing: "Printing",
    printOver: "printing complete",
    printFail: "Printing failed",
    printOvers: "print abort",
    printStay: "Printing Paused",
    notTestMaterial: "Consumable not detected",
    stopUnexpect: "Detects the last unexpectedly stopped file",
  },
  // 摄像头模块
  cameraModule: {
    cameraShow: "Monitoring",
    pleaseCheck: "Please check",
    printAsset: "Does the printer camera device work properly.",
  },
  // 温度管控模块
  temperatureControl: {
    temp: "Temperature",
    progrems: "Item",
    nows: "Current",
    goals: "Target",
    penzui: "Nozzle",
    hotBed: "HotBed",
    temFanwei: "The temperature range is unreasonable",
  },
  // 网床模块
  wangchuang: {
    name: "Bed mesh",
    delete: "Clear bednet data",
    showBed: "Show bed level",
  },
  // 错误指令模块
  errorMessage: {
    printNoReady:
      "The printer is not ready, the Klippy host is trying to connect, please try again later!",
    chuanganqi: "BLTouch failed to verify sensor status!",
    paramsNOuse: "The parameter of display_template is invalid",
    weizhizu: "Unknown display_data group",
    wuxiaozi: "The invalid glyph line is in",
    zixing: "zixing",
    buzhengque: "Incorrect line",
    bufenmingc: "Part Name",
    wuxiao: "Invalid",
    yinjiao: "All pins of hd44780 must be on the same MCU",
    chouxiang: "Abstract MenuContainer cannot be instantiated directly",
    caidanxiang: "menu item",
    bujieshou: "Not accepted!",
    wrongType: "Wrong type, should be MenuContainer",
    select: "Options",
    myChose: "Choose",
    notUseSelect: "Not a valid selection",
    weizhicai: "Unknown menu item",
    wujiexi: "Unable to parse encoder_pins",
    tongMcu: "spi pins must be on the same MCU",
    ceshizhi: "The measured value of adxl345 was not found",
    wanggewai:
      "bed_mesh: error, fade_target is outside the grid Z range\nminimum value",
    zuidazhi: "Maximum value:",
    zuixiaozhi: "Minimum value",
    fade_target: "fade_target:",
    wangge2:
      "bed_mesh: The mesh is beyond the fade range, please refer to the fade_start and fade_end options of example-extras.cfg. Fade distance: ",
    wanggexiao: "Grid Minimum:",
    wanggezuida: "Grid Maximum:",
    wanggeshuiping:
      "Grid horizontal adjustment: Error when splitting and moving",
    dicengtan: "The bed has not been detected",
    meijiazai: "Mesh not loaded to offset",
    shengzuobiao:
      "bed_mesh: Unable to generate coordinates for the faulty area at the index.",
    sancicacao:
      "bed_mesh: Bicubic interpolation detected with probe counts less than 4 points. Forced Lagrangian interpolation. Configured probe counts:",
    bupipei: "bed_mesh: points do not match, orig = (",
    probed: "), probed = ",
    ycontrol: "bed_mesh: Error finding y control points",
    guiling: "Return to zero first:",
    Chaofanwei: "Out of range: [",
    yidongchang: "Extrusion only moves too long (",
    mmvs: "mm vs",
    xiangxinxi: "\nSee the max_extrude_only_distance configuration option`",
    jiemianxuanx: "Options in Sectional Bed Mesh",
    bixuyou: "Must have at least",
    dezuida: "The maximum value must be",
    fenxican: "Unable to parse parameter",
    canshu: "Parameters",
    dezuixiaob: "The minimum value must be",
    dezuidab: "The maximum value must be",
    shangcuo: "The above error",
    shangcuo1: "Error on: Missing",
    shangcuo2: "The above error: must have",
    bidayu: "Must be greater than",
    bixiaoyu: "Must be less than",
    weizhimeib: "Warning: No change in position (stepping resolution reached)",
    tanshibai:
      "Manual probing failed! Use the TESTZ command to position the nozzle before running ACCEPT.",
    xunidian: "Probe virtual endpoints are only used as endpoint pins",
    duanzhidian: "Unable to pull up/reverse probe virtual end stop",
    yidongpenzui: "Detect nozzles moving during deactivation of gcode script",
    deltadayin: "Delta calibrate is only available for Delta printers",
    celiangzhi: "Not all measurements are provided",
    jibenjioazhun: "Must first run basic calibration with DELTA_CALIBRATE",
    bixuyou1: "must have",
    gezhi: "a value",
    jiaozhunW: "Unknown Calibration Operation",
    dostaryun: "Dotstar pins must be on the same mcu",
    tongjixin: "Stepper statistics are not available",
    bujinqi: "No stepper found. (Make sure to zero it at least once.)",
    weizhiHong: "Unknown gcode macro variable",
    wufajiang: "Unable to transfer",
    wenzixi: "Analyze as text",
    zhidingbian: "G-Code movement transformation has been specified",
    zhongsuwuxiao: "The speed in is invalid",
    wufafenxi: "Unable to analyze movement",
    weizhig: "Unknown gcode status:",
    fenwuxiao: "The HTU21D resolution is invalid. The valid value is",
    replicape: "replicape does not support Pin type",
    jingtaiyin: "Static pins cannot be turned off",
    fanzhuanyin: "Cannot reverse Replicape virtual enable pin",
    pwnxin: "Replicape cannot determine the pwm chip",
    weizhipin: "Unknown replicape pin",
    offetxiao: "Error on touch: must have a minimum value of z_offset",
    jiuzheng:
      "\nOnce the underlying problem has been corrected, use the 'reboot'\ncommand to reload the configuration and restart the host software.\nPrinter stopped",
    wufenxi: "Unable to analyze options",
    weizhunbei: "The printer is not ready",
    bianliangwen: "Unable to parse existing variable file",
    baobianl: "Unable to save variables",
    mcngui: "Multi-mcu homing is not supported on multi-mcu shared axes",
    jingyin: "Static pins cannot be turned off",
    guanjizhi:
      "The starting value of the pin with the maximum duration must be equal to the shutdown value",
    chishida: "The maximum duration of the digital pin is too large",
    ruanpwn: "shutdown on soft pwm must be 0.0 or 1.0",
    zhouqida: "PWM pin cycle time is too large",
    zhouqidad: "PWM cycle time is too large",
    wait: "Wait",
    xiangyingcao: "Response timed out",
    chongqimcm: "Try to restart the MCU",
    fail: "Failure",
    mcu: "MCU",
    CRCpei: "CRC does not match configuration",
    cajiao: "Pin",
    feiMcu: "not mcu",
    chajiaom: "Valid pin names on",
    gengMcn: "Unable to update MCU",
    peizhi: "Configuration, because it is closed",
    wupeiM: "Unable to configure MCU",
    peiqiM: "MCU during configuration",
    chucuo1: "Error: ",
    zipeishi: "MCU automatic reset failed",
    keyishao: "There are too few moves available on the MCU",
    buzhiyin: "mcu does not support pin type",
    zhougewu: "The axis format is invalid",
    wufenzhou: "Unable to analyze axis direction",
    buzhishu: "Unsupported output",
    jinreson: ", only supports resonances and raw_data",
    weizhishu:
      "No output specified, at least one resonances and raw_data must be set in the output parameters",
    zhoujiashu: "No data was measured by the -axis accelerometer",
    xiangleiy: "Response Type",
    wuxiaob: "Invalid. Must be one of echo, command or error",
    bixuz: "must be in mcu",
    shangzhid: "Up Designated",
    Weizhi: "Unknown",
    yuanjisuan: "Remote calculation error:",
    chujiashu: "Process accelerometer data",
    shineicuo: "An internal error occurred",
    xiewenj: "Write to file",
    shicucuo: "An error occurred:",
    pingst: "skew_correction: plane[",
    tiaomug: "The entry format of] is incorrect\n",
    lengduanfan:
      "Max31856: Cold Junction Range Fault, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    reoufan:
      "Max31856: Thermocouple range failure, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    lengduangao:
      "Max31856: Cold Junction High Fault, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. Printer is off",
    lengthduandi:
      "Max31856: Cold Junction Low Fault, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. Printer is off",
    reougao:
      "Max31856: Thermocouple High Fault, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    reouDi:
      "Max31856: Thermocouple Low Fault, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    guodianya:
      "Max31856: Overvoltage/Undervoltage fault, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    reoukailu:
      "Max31856: Open thermocouple failure, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    kailu:
      "Max31855: Open circuit failure, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    duididuan:
      "Max31855: Short to ground, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    vcduan:
      "Max31855: Short to Vcc, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    sheIDw:
      "Max6675: Wrong device ID, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    redianoukai:
      "Max6675: Open thermocouple failure, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    Shuruduan:
      "Max31865 RTD input disconnected, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. Printer is off",
    rtdduanlu:
      "Max31865 RTD input shorted, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    daqiagkai:
      "Max31865 VREF - Greater than 0.85*VBIAS, FORCED - ON, Once the underlying problem is corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. Printer is off",
    xiaoqiangkai:
      "`Max31865 VREF - less than 0.85*VBIAS, force-on, once the underlying problem is corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer is turned off",
    weizhid:
      "Max31865 Unspecified error, once the underlying problem has been corrected, use the 'FIRMWARE_RESTART' command to reset the firmware, reload the configuration, and restart the host software. The printer has shut down",
    xuanxiang: "Options",
    zaijie: "in festival",
    bixudi: "It is a must",
    baojietou: "This file does not contain a section header.",
    jiuzhengwen:
      " \nAfter correcting the underlying problem, use the RESTART\ncommand to reload the configuration and restart the host software.\nPrinter stopped",
    wenjianjie: "The file contains a parsing error:",
    chongjiapei:
      "\nAfter correcting the underlying problem, use the RESTART\ncommand to reload the configuration and restart the host software.\nThe printer stops",
    dianjiyi: "The motor drive is abnormal",
    neibvucuo: "Internal error",
    tongxunyi: "communication abnormal",
    yuqijia: "Not heated as expected",
    rechuangyi: "The temperature of the hot bed is abnormal",
    jichuyi: "The extruder is abnormal",
    dayinyi: "Print file coordinates are abnormal",
    dayinzhi: "Print quality problems detected and printing paused",
    weizhicuo: "An unknown printing error has been detected",
    wenqiangyi: "The chamber temperature is abnormal",
    dayinwenyi: "Print file coordinates are abnormal",
    zhenwenyou: "The vibration optimization sensor is abnormal",
    fenshanyi: "The fan is abnormal",
    wangluoyi: "Network exception",
    dayinzhi1: "Print quality problem detected",
    ztouchyi: "A z-Touch error has been detected, please try again",
    wenfuyi: "File copy error, please try again",
    weizhiyi: "Unknown exception",
    yidongcuo: "Move axis error",
    dayinjiuyu:
      "The printer is not ready, the Klippy host is trying to connect, please try again later",
  },
};
