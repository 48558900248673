import { defineStore } from "pinia";
export const useConfigStore = defineStore("configStore", {
  state: () => {
    return {
      // X电机基本配置
      stepper_x: null,
      // X电机电流配置
      tmc2209_stepper_x: null,
      // Y电机基本配置
      stepper_y: null,
      // Y电机电流配置
      tmc2209_stepper_y: null,
      // Z电机基本配置
      stepper_z: null,
      // Z电机电流配置
      tmc2209_stepper_z: null,
      // 挤出机配置
      extruder: null,
      // 挤出机喷嘴风扇
      heater_fan_hotend_fan: null,
      // 挤出机电流
      tmc2209_extruder: null,
      // 热床配置
      heater_bed: null,
      // 网床调平
      bed_mesh: null,
      // 打印机限制
      printer: null,
    };
  },
});
