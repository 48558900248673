import { createRouter, createWebHashHistory } from "vue-router";
// 主布局整合容器
import Layout from "@/components/Layout.vue";
// 主菜单界面
import Home from "@/views/home/index.vue";
// 准备和调整
// import PrepareAdjust from "@/views/prepare/index.vue";
// 打印机历史记录
// import PrintHistory from "@/views/printHistory/index.vue";
// 设备管理及信息
// import DeviceManagement from "@/views/deviceManagement/index.vue";
// 配置管理
// import ConfigManagement from "@/views/configManagement/index.vue";
// Gcode预览
// import GcodePreview from "@/views/gcodePreview/index.vue";
const routes = [
  {
    path: "/",
    name: "layout",
    redirect: "/home",
    component: Layout,
    children: [
      // 首页主界面
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      // 准备和调整
      // {
      //   path: "/prepare",
      //   name: "PrepareAdjust",
      //   component: PrepareAdjust,
      // },
      // // 打印历史记录
      // {
      //   path: "/printHistory",
      //   name: "PrintHistory",
      //   component: PrintHistory,
      // },
      // // 设备管理及信息
      // {
      //   path: "/deviceManagement",
      //   name: "deviceManagement",
      //   component: DeviceManagement,
      // },
      // // 配置管理
      // {
      //   path: "/configManagement",
      //   name: "configManagement",
      //   component: ConfigManagement,
      // },
      // // gCODE预览
      // {
      //   path: "/gcodePreview",
      //   name: "gcodePreview",
      //   component: GcodePreview,
      // },
    ],
  },
];
// 更改路由哈希:兼容打包资源请求
const history = createWebHashHistory();
const router = createRouter({
  history,
  routes,
});
export default router;
