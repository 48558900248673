const formatSize = (size) => {
  let data = "";
  if (size < 0.1 * 1024) {
    //如果小于0.1KB转化成B
    data = size.toFixed(2) + "B";
  } else if (size < 0.1 * 1024 * 1024) {
    //如果小于0.1MB转化成KB
    data = (size / 1024).toFixed(2) + "KB";
  } else if (size < 0.1 * 1024 * 1024 * 1024) {
    //如果小于0.1GB转化成MB
    data = (size / (1024 * 1024)).toFixed(2) + "MB";
  } else {
    //其他转化成GB
    data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
  }
  let sizestr = data + "";
  let len = sizestr.indexOf(".");
  let dec = sizestr.substr(len + 1, 2);
  if (dec == "00") {
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
  }
  return sizestr;
};

const formatTime = (mss) => {
  const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));

  return hours + ":" + minutes;
};

const getNowFormatDate = (min) => {
  let date = new Date();
  let seperator2 = ":";
  //前十分钟时间

  let interTimes = min * 60 * 1000;
  date = new Date(Date.parse(date) - +interTimes);

  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  let hour = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  if (hour >= 0 && hour <= 9) {
    hour = "0" + hour;
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = "0" + minutes;
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = "0" + seconds;
  }
  let currentdate = hour + seperator2 + minutes + seperator2 + seconds;
  return currentdate;
};

//时间戳转日期格式
const formatDateTime = function (time, format) {
  const t = new Date(time * 1000);
  const tf = (i) => (i < 10 ? "0" : "") + i;
  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
    switch (a) {
      case "yyyy":
        return tf(t.getFullYear());
      case "MM":
        return tf(t.getMonth() + 1);
      case "mm":
        return tf(t.getMinutes());
      case "dd":
        return tf(t.getDate());
      case "HH":
        return tf(t.getHours());
      case "ss":
        return tf(t.getSeconds());
    }
  });
};

//  秒数转化为时分秒
export function formatSeconds(value) {
  //  秒
  let second = parseInt(value);
  //  分
  let minute = 0;
  //  小时
  let hour = 0;
  //  天
  //  let day = 0
  //  如果秒数大于60，将秒数转换成整数
  if (second > 60) {
    //  获取分钟，除以60取整数，得到整数分钟
    minute = parseInt(second / 60);
    //  获取秒数，秒数取佘，得到整数秒数
    second = parseInt(second % 60);
    //  如果分钟大于60，将分钟转换成小时
    if (minute > 60) {
      //  获取小时，获取分钟除以60，得到整数小时
      hour = parseInt(minute / 60);
      //  获取小时后取佘的分，获取分钟除以60取佘的分
      minute = parseInt(minute % 60);
      //  如果小时大于24，将小时转换成天
      //  if (hour > 23) {
      //    //  获取天数，获取小时除以24，得到整天数
      //    day = parseInt(hour / 24)
      //    //  获取天数后取余的小时，获取小时除以24取余的小时
      //    hour = parseInt(hour % 24)
      //  }
    }
  }
  let result = "" + parseInt(second) + "秒";
  if (minute > 0) {
    result = "" + parseInt(minute) + "分" + result;
  }
  if (hour > 0) {
    result = "" + parseInt(hour) + "小时" + result;
  }
  //  if (day > 0) {
  //    result = '' + parseInt(day) + '天' + result
  //  }
  console.log("result：", result);
  return result;
}
// 将时间戳转成日期
export function timestampToTime(timestamp) {
  var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = date.getDate() + " ";
  var h = date.getHours() + ":";
  var m = date.getMinutes() + ":";
  var s = date.getSeconds();
  return Y + M + D + h + m + s;
}
// 加载本地文件
const loadFile = function (name) {
  // name为文件所在位置
  let xhr = new XMLHttpRequest();
  const okStatus = document.location.protocol === "file:" ? 0 : 200;
  xhr.open("GET", name, false);
  xhr.overrideMimeType("text/html;charset=utf-8"); //默认为utf-8
  xhr.send(null);
  return xhr.status === okStatus ? xhr.responseText : null;
};
// 1.封装节流函数（使用定时器） 节流重在加锁 timer=timeout
const throttle = function (fn, time) {
  //3. 通过闭包保存一个 "节流阀" 默认为false
  let temp = false;
  return function () {
    //8.触发事件被调用 判断"节流阀" 是否为true  如果为true就直接trurn出去不做任何操作
    if (temp) {
      return;
    } else {
      //4. 如果节流阀为false  立即将节流阀设置为true
      temp = true; //节流阀设置为true
      //5.  开启定时器
      setTimeout(() => {
        //6. 将外部传入的函数的执行放在setTimeout中
        fn.apply(this, arguments);
        //7. 最后在setTimeout执行完毕后再把标记'节流阀'为false(关键)  表示可以执行下一次循环了。当定时器没有执行的时候标记永远是true，在开头被return掉
        temp = false;
      }, time);
    }
  };
};
// 封装防抖函数(使用定时器)
const debounceFn = (fun, delay = 500) => {
  var timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      fun.call(this, ...arguments);
    }, delay);
  };
};
const downFile = (url, name) => {
  console.log(url, "url");
  let x = new XMLHttpRequest();
  x.open("GET", url, true);
  x.responseType = "blob";
  x.onload = (e) => {
    let url = window.URL.createObjectURL(x.response);
    let a = document.createElement("a");
    a.href = url;
    a.download = name;
    a.click();
  };
  x.send();
};
// 原生方式给dom元素添加类
const addClass = function(ele, str) {
  if (ele.className) {
    return
  } else {
    ele.className = str;
  }
}
export {
  formatSize,
  formatTime,
  getNowFormatDate,
  loadFile,
  throttle,
  debounceFn,
  downFile,
  addClass,
  formatDateTime,
};
