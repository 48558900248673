export default {
  // 通用配置
  configuration: {
    sureThat: "确认",
    resetIt: "重启",
    sureInfo: "确定",
    cancel: "取消",
    closeWarn: "websocket服务处于关闭状态!",
    guiweiPrint: "请先归位打印机!",
    allIt: "全部",
    assetDi: "设备到底啦!",
    crealityPrint: "创想打印",
    resetFuwu: "确定重启当前服务?",
    Tips: "提示",
    resetSuccess: "重启成功!",
    quxiao: "已取消",
    paichuSuccess: "排除对象成功!",
    surePaichu: "是否要排除选中模型?",
    paichuObject: "排除对象",
    paichuguol: "此对象已排除!",
  },
  // 文件管理国际化
  FileManage: {
    insert: "导入",
    files: "文件",
    fileName: "文件名称",
    fileSize: "文件大小",
    layerHeight: "层高",
    time: "时间",
    hcLehgth: "耗材长度",
    name: "名称",
    status: "状态",
    noFinish: "未完成",
    finish: "完成",
    startTime: "开始时间",
    endTime: "结束时间",
    allTime: "总时长",
    size: "大小",
    historyRecord: "历史记录",
    startPrint: "开始打印",
    deleteFile: "删除文件",
    deleteSuccess: "删除成功!",
    calcelDelete: "取消删除",
    newName: "重命名",
    inputFilename: "请输入文件名",
    fileNameNull: "文件名不能为空!",
    newNameScuess: "重命名成功!",
    newNameCancel: "重命名取消",
    sureDelete: "你确定删除该文件吗?",
    homeTips: "请您先归位打印机,再进行开始打印操作!",
    gcodeSilceTips: "请导入Gcode切片文件!",
    samePrinting: "该文件正在打印中，请打印完成后再导入",
    insertSuccess: "导入成功",
    uploadFailed: "导入失败",
  },
  // 运动控制国际化
  moveControl: {
    infoControl: "运动控制",
    printSpeed: "打印速度: ",
    windSpeed: "风速",
    modelFengshan: "模型风扇",
    qiangtiFengshan: "机箱风扇",
    fuzhuFengshan: "辅助风扇",
    lFengshan: "LED灯开关",
    haomi: "毫米",
    jichuDistence: "挤出距离",
    jichuSpeed: "挤出速度",
    haomiMiao: "毫米 / 秒",
    printWarning: "打印中状态不允许机器操作",
    shuruMubiao: "请重新输入目标值!",
  },
  //  打印状态管控
  printStatus: {
    pause: "暂停",
    goOn: "继续",
    off: "终止",
    cancel: "取消",
    gcodePre: "gcode预览图",
    printTime: "打印时间: ",
    lastTime: "剩余时间: ",
    printPause: "打印停止",
    printing: "局域网打印中",
    printOver: "打印完成",
    printFail: "打印失败",
    printOvers: "打印中止",
    printStay: "打印暂停",
    notTestMaterial: "未检测到耗材",
    stopUnexpect: "检测到上次意外停止的文件",
  },
  // 摄像头模块
  cameraModule: {
    cameraShow: "摄像头显示",
    pleaseCheck: "请您检查",
    printAsset: "打印机摄像设备是否正常工作",
  },
  // 温度管控模块
  temperatureControl: {
    temp: "温度",
    progrems: "项目",
    nows: "当前",
    goals: "目标",
    penzui: "喷嘴",
    hotBed: "热床",
    temFanwei: "温度范围不合理",
  },
  // 网床模块
  wangchuang: {
    name: "网床",
    delete: "清除床网数据",
    showBed: "显示热床水平面",
  },
  // 错误指令模块
  errorMessage: {
    printNoReady: "打印机未就绪、Klippy主机正在尝试连接、请稍后重试!",
    chuanganqi: "BLTouch验证传感器状态失败!",
    paramsNOuse: "display_template的参数无效",
    weizhizu: "未知的display_data组 ",
    wuxiaozi: "无效的字形行在",
    zixing: "字形",
    buzhengque: "不正确的行",
    bufenmingc: "部分名称",
    wuxiao: "无效",
    yinjiao: "hd44780 所有引脚必须在同一个MCU上",
    chouxiang: "抽象的MenuContainer不能直接实例化",
    caidanxiang: "菜单项",
    bujieshou: "不被接受!",
    wrongType: "错误的类型,应该是MenuContainer",
    select: "选项",
    myChose: "的选择",
    notUseSelect: "不是一个有效的选择",
    weizhicai: "未知的菜单项",
    wujiexi: "无法解析encoder_pins",
    tongMcu: "spi引脚必须在同一个MCU上",
    ceshizhi: "没有找到adxl345的测量值",
    wanggewai: "bed_mesh: 错误， fade_target 位于网格Z范围之外\n最小值",
    zuidazhi: "最大值:",
    zuixiaozhi: "最小值",
    fade_target: "fade_target:",
    wangge2:
      "bed_mesh: 网格超出fade范围, 请参考example-extras.cfg的fade_start和fade_end 选项。fade 距离: ",
    wanggexiao: "网格最小值：",
    wanggezuida: "网格最大值：",
    wanggeshuiping: "网格水平调整：拆分移动时出错",
    dicengtan: "床层尚未探测",
    meijiazai: "没有加载到偏移的网格",
    shengzuobiao: "bed_mesh:无法生成坐标 对于索引处的故障区域。",
    sancicacao:
      "bed_mesh: 检测到探头计数小于4个点的双三次插值。强制拉格朗日插值。配置的探测器计数：",
    bupipei: "bed_mesh: 点不匹配, orig = (",
    probed: "), probed = ",
    ycontrol: "bed_mesh: 查找y控制点时出错",
    guiling: "先归零:",
    chaofanwei: "超出范围: [",
    yidongchang: "仅挤出移动过长（",
    mmvs: "mm vs",
    xiangxinxi: "\n有关详细信息，请参阅“max_extrude_only_distance”配置选项`",
    jiemianxuanx: "截面床网中的选项",
    bixuyou: "必须至少有",
    dezuida: "的最大值必须为",
    fenxican: "无法分析参数",
    canshu: "参数",
    dezuixiaob: "的最小值必须为",
    dezuidab: "的最大值必须为",
    shangcuo: "上的错误",
    shangcuo1: "上的错误：缺少",
    shangcuo2: "上的错误：必须有",
    bidayu: "必须大于",
    bixiaoyu: "必须小于",
    weizhimeib: "警告：位置没有变化（达到步进分辨率）",
    tanshibai: "手动探测失败！在运行ACCEPT之前，使用TESTZ命令定位喷嘴。",
    xunidian: "探测虚拟终点仅用作终点pin",
    duanzhidian: "无法上拉/反转探针虚拟端止点",
    yidongpenzui: "探测停用gcode脚本期间移动的喷嘴",
    deltadayin: "Delta calibrate仅适用于Delta打印机",
    celiangzhi: "并非所有测量值都提供",
    jibenjioazhun: "必须首先使用DELTA_CALIBRATE运行基本校准",
    bixuyou1: "必须有",
    gezhi: "个值",
    jiaozhunW: "未知校准操作",
    dostaryun: "Dotstar引脚必须位于同一个mcu上",
    tongjixin: "步进器的统计信息不可用",
    bujinqi: "未找到步进器。（确保至少归零一次。）",
    weizhiHong: "未知gcode宏变量",
    wufajiang: "无法将",
    wenzixi: "作为文字进行分析",
    zhidingbian: "已指定G-Code移动变换",
    zhongsuwuxiao: "中的速度无效",
    wufafenxi: "无法分析移动",
    weizhig: "未知的gcode状态:",
    fenwuxiao: "HTU21D分辨率无效。有效值为",
    replicape: "replicape不支持Pin类型",
    jingtaiyin: "静态引脚不能有关机值",
    fanzhuanyin: "无法反转Replicape虚拟启用引脚",
    pwnxin: "Replicape无法确定pwm芯片",
    weizhipin: "未知的replicape pin ",
    offetxiao: "touch上的错误：必须有z_offset最小值",
    jiuzheng:
      "\n一旦基本问题得到纠正，请使用“重新启动”\n命令重新加载配置并重新启动主机软件。\n打印机已停止",
    wufenxi: "无法分析选项",
    weizhunbei: "打印机未准备",
    bianliangwen: "无法分析现有变量文件",
    baobianl: "无法保存变量",
    mcngui: "多mcu共享轴上不支持多mcu归位",
    jingyin: "静态引脚不能有关机值",
    guanjizhi: "具有最大持续时间的引脚的起始值必须等于关机值",
    chishida: "数字pin最大持续时间过大",
    ruanpwn: "软pwm上的停机值必须为0.0或1.0",
    zhouqida: "PWM引脚周期时间过大",
    zhouqidad: "PWM周期时间过大",
    wait: "等待",
    xiangyingcao: "响应超时",
    chongqimcm: "尝试重新启动MCU",
    fail: "失败",
    mcu: "MCU",
    CRCpei: "CRC与配置不匹配",
    cajiao: "插脚",
    feiMcu: "不是mcu",
    chajiaom: "上的有效插脚名称",
    gengMcn: "无法更新MCU",
    peizhi: "配置,因为它已关闭",
    wupeiM: "无法配置MCU",
    peiqiM: "配置期间MCU",
    chucuo1: "出错: ",
    zipeishi: "MCU自动重置失败",
    keyishao: "MCU上可用的移动太少",
    buzhiyin: "mcu不支持引脚类型",
    zhougewu: "轴的格式无效",
    wufenzhou: "无法分析轴方向",
    buzhishu: "不支持的输出",
    jinreson: ",仅支持resonances和raw_data",
    weizhishu: "未指定输出，必须在输出参数中设置至少一个resonances和raw_data",
    zhoujiashu: "-轴加速计未测量到数据",
    xiangleiy: "响应类型",
    wuxiaob: "无效。必须是“echo”、“command”或“error”之一",
    bixuz: "必须在mcu",
    shangzhid: "上指定",
    weizhi: "未知",
    yuanjisuan: "远程计算出错：",
    chujiashu: "处理加速计数据",
    shineicuo: "时出现内部错误",
    xiewenj: "写入文件",
    shicucuo: "时出错：",
    pingst: "skew_correction：平面[",
    tiaomug: "]的条目格式不正确\n",
    lengduanfan:
      "Max31856：冷端范围故障,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    reoufan:
      "Max31856：热电偶范围故障,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    lengduangao:
      "Max31856：冷端高故障,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    lengduandi:
      "Max31856：冷端低故障,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    reougao:
      "Max31856：热电偶高故障,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    reouDi:
      "Max31856：热电偶低故障,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    guodianya:
      "Max31856：过电压/欠电压故障,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    reoukailu:
      "Max31856：热电偶开路故障,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",

    kailu:
      "Max31855：开路故障,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    duididuan:
      "Max31855：对地短路,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    vcduan:
      "Max31855：对Vcc短路,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    sheIDw:
      "Max6675：设备ID错误,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    redianoukai:
      "Max6675：热电偶开路故障,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    shuruduan:
      "Max31865 RTD输入断开,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    rtdduanlu:
      "Max31865 RTD输入短路,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    daqiagkai:
      "Max31865 VREF-大于0.85*VBIAS，强制-打开,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    xiaoqiangkai:
      "`Max31865 VREF-小于0.85*VBIAS，强制-打开,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    weizhid:
      "Max31865未指定错误,一旦基本问题得到纠正，请使用“FIRMWARE_RESTART”命令重置固件，重新加载配置，然后重新启动主机软件。打印机已关闭",
    xuanxiang: "选项",
    zaijie: "在节",
    bixudi: "是必须的",
    baojietou: "该文件不包含节头。",
    jiuzhengwen:
      " \n纠正基础问题后，使用“RESTART”\n命令重新加载配置并重新启动主机软件。\n打印机停止",
    wenjianjie: "文件包含解析错误：",
    chongjiapei:
      "\n纠正基础问题后，使用“RESTART”\n命令重新加载配置并重新启动主机软件。\n打印机停止",
    dianjiyi: "电机驱动异常",
    neibvucuo: "内部错误",
    tongxunyi: "通讯异常",
    yuqijia: "未按预期加热",
    rechuangyi: "热床温度异常",
    jichuyi: "挤出机异常",
    dayinyi: "打印文件坐标异常",
    dayinzhi: "检测到打印质量问题并暂停打印",
    weizhicuo: "检测到打印发生未知错误",
    wenqiangyi: "温腔温度异常",
    dayinwenyi: "打印文件坐标异常",
    zhenwenyou: "振纹优化传感器异常",
    fenshanyi: "风扇异常",
    wangluoyi: "网络异常",
    dayinzhi1: "检测到打印质量问题",
    ztouchyi: "检测到z-Touch异常，请重试",
    wenfuyi: "文件复制异常，请重试",
    weizhiyi: "未知异常",
    yidongcuo: "移动轴错误",
    dayinjiuyu: "打印机未就绪、Klippy主机正在尝试连接、请稍后重试",
  },
};
