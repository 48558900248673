<template>
  <div
    id="nav"
    v-loading="loading"
    element-loading-text="设备连接中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(255, 255, 255, 0.8)"
    style="position: unset !important"
  >
    <router-view v-if="!loading" />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onBeforeMount, onMounted } from "vue";
import { usePrepareStore, useMainStore } from "@/stores";
// import { useI18n } from "vue-i18n";
// const { locale } = useI18n();
import errorCode from "@/utils/errorCode";
import { ElMessage } from "element-plus";
const loading = ref(true);
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `;
onBeforeMount(() => {
  // 唤醒国际化
  const { $t } = getCurrentInstance().proxy;
  console.log("什么国际化612", $t("FileManage.files"));
  const { proxy } = getCurrentInstance();
  const formateCode = (code) => {
    let str = code.toString();
    while (str.length < 4) str = "0" + str;
    return `E${str}`;
  };
  // 服务断线时需屏蔽全局Loading
  proxy.$messageCenter.on("websocket_open", (data) => {
    loading.value = !data;
    if (!data) return;
    let el = null;
    proxy.$messageCenter.on("message_change", (data) => {
      if (data === "ok") return;
      const responData = JSON.parse(data);
      // console.log("什么数据", responData);
      const mainStore = useMainStore();
      for (const [key, value] of Object.entries(responData)) {
        // 定制XYZ位置、归位按钮初始获取的数据格式
        if (key === "curPosition" || key === "autohome") {
          mainStore[key] = value.split(" ");
        } else {
          mainStore[key] = value;
        }
      }

      if (responData.err && errorCode[responData.err.errcode]) {
        const { errcode: code, key } = responData.err;
        const errCode = [1, 2, 3, 4, 5, 6, 8, 200];
        const isErr = errCode.includes(code);
        const btnText = isErr
          ? $t("configuration.resetIt")
          : $t("configuration.sureThat");
        // errorCode国际化方案
        const htmlStr = `<div class= "codeMessage">
           <p> ${formateCode(code)}：${errorCode[code]([key], $t)}</p>
            <button class="reboot">${btnText}</button>
          </div>`;
        el = ElMessage({
          dangerouslyUseHTMLString: true,
          message: htmlStr,
          type: "warning",
          duration: 0,
        });

        const rebootDom = document.querySelector(".reboot");
        if (rebootDom) {
          rebootDom.addEventListener("click", () => {
            if (isErr) {
              proxy.$webSocket.sendMsg({
                method: "set",
                params: { restartFirmware: 1 },
              });
            } else {
              proxy.$webSocket.sendMsg({
                method: "set",
                params: { cleanErr: 1 },
              });
              el.close();
            }
          });
        }
      }
      if (responData.err && responData.err.errcode === 0 && el) el.close();
      // 准备与调整的数据统筹
      const prepareStore = usePrepareStore();
      const prepareKey = [
        "materialDetect",
        "videoElapse",
        "nozzleMoveSnapshot",
        "videoElapseFrame",
        "videoElapseInterval",
      ];
      for (const [key, value] of Object.entries(responData)) {
        if (prepareKey.includes(key)) {
          prepareStore[key] = value;
        }
      }
    });
  });
});
// 自适应设备布局
onMounted(() => {
  getWindowResize();
  // // 获取设备尺寸,动态兼容H5端功能展示
  window.addEventListener("resize", getWindowResize);
  // 获取屏幕尺寸
  function getWindowResize() {
    // console.log("设备切换啦", document.documentElement.clientWidth);
    const viewWidth = document.documentElement.clientWidth;
    // const viewHeight = document.documentElement.clientHeight;
    const appCon = document.getElementById("app");
    // 小于900就那个,大于900不带叼。
    if (viewWidth <= 900) {
      appCon.style.minWidth = viewWidth + "px";
    }
    // appCon.style.minHeight = viewHeight + "px";
  }
});
</script>

<style lang="scss">
@media screen and (max-width: 1200px) {
  #app {
    min-width: 540px !important;
  }
}
#app {
  font-family: Source Han Sans CN, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  min-height: 100vh;
  background: #363638;
  user-select: none;
  min-width: 170vh;
}
body {
  margin: 0;
}
.codeMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 5px 0 !important;
  }
  .reboot {
    // display: var(--showBtn);
    width: 100px;
    height: 24px;
    border-radius: 12px;
    font-size: 12px;
    border: none;
    box-shadow: 0px 3px 3px 0px #303034;
    background: #515157;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
  }
}
</style>
