import { defineStore } from "pinia";
export const usePrepareStore = defineStore("prepareStore", {
  state: () => {
    return {
      materialDetect: 0,
      videoElapse: 0,
      nozzleMoveSnapshot: 0,
      videoElapseFrame: 0,
      videoElapseInterval: 0,
    };
  },
});
