<template>
  <div class="control-main-set" :class="{ controlAnimal: !state.foldFlag }">
    <!-- 头部组件 -->
    <HeaderBar
      :title="$t('moveControl.infoControl')"
      class="header"
      :icon="'&#xe637;'"
    >
      <template v-slot:right>
        <div class="right-feature"></div>
        <!-- 收起面板 -->
        <div class="fold">
          <!-- 折叠 -->
          <i class="iconfont" @click.stop="foldThePage" v-if="state.foldFlag"
            >&#xe65a;</i
          >
          <!-- 展开 -->
          <i class="iconfont" @click.stop="openThePage" v-else>&#xe659;</i>
        </div>
      </template>
    </HeaderBar>
    <div class="container_warp" v-show="state.foldFlag">
      <!-- 中间组件 -->
      <div class="middleContent">
        <div class="topPrintshow">
          <!-- 左侧通用btn -->
          <div
            :style="{
              top: item.topPosition,
              left: item.leftPosition,
              justifyContent: item.justFy,
              flexDirection: item.direction,
              width: item.width,
              height: item.height,
              color: item.color,
              background: item.background,
            }"
            class="squre-item"
            v-loading="item.loading"
            :element-loading-background="item.background"
            v-for="(item, index) in showSettingConfig"
            :key="index"
            @click="debounceGo(item, index)"
          >
            <div v-if="item.direction">{{ item.showInfo }}</div>
            <div>
              <i
                :class="[
                  'iconfont',
                  item.iconShowInfo,
                  { 'squre-shouhui': state.nozzleTemp >= 180 },
                ]"
                :style="{
                  fontSize: item.size,
                }"
              ></i>
            </div>
          </div>
          <!-- 中间打印机移动btn -->
          <div
            :style="{
              top: item.topPosition,
              left: item.leftPosition,
              justifyContent: item.justFy,
              flexDirection: item.direction,
              width: item.width,
              height: item.height,
              color: item.color,
              background: item.background,
            }"
            :class="[
              'squre-item1',
              { 'squre-itemClick': item.clickFlag == true },
              item.itemClass,
            ]"
            v-for="(item, index) in showSettingMove"
            :key="index"
            @click="setPrintmove(item, showSettingMove)"
          >
            <div v-if="item.direction">{{ item.showInfo }}mm</div>
            <div>
              <i
                :class="['iconfont', item.iconShowInfo]"
                :style="{
                  fontSize: item.size,
                }"
              ></i>
            </div>
          </div>
          <!-- 右侧input -->
          <div class="midinput">
            <div
              v-for="(item, index) in showInputConfig"
              :key="index"
              :class="['midInnerInput']"
            >
              <el-input
                v-model="item.firstInfo"
                :readonly="item.BtnshowFlag"
                @focus="onInputFocus(item)"
                @blur="onInputBlur(item)"
                @change="onInputChange(item)"
              >
                <template #append>
                  <span style="color: #ffffff; padding-right: 5px">{{
                    item.value
                  }}</span>
                </template>
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <!-- 打印速度数据 -->
      <div class="hot_bend">
        <div
          class="hot_bend_item"
          v-for="(item, index) in showBendMouth"
          :key="index"
        >
          <div class="left_info">{{ item.temInfo }}</div>
          <div class="elinput_contain">
            <el-input
              v-model="item.temTarget"
              @change="temperHandler(item.type, item.temTarget)"
            >
            </el-input>
          </div>
          <div class="last_info">{{ `/ ` + item.temNow + item.company }}</div>
        </div>
      </div>
      <!-- 常用开关: 新UI界面改动: 6.19 -->
      <div class="ofen_switch">
        <div
          class="usb-switch"
          v-for="(item, index) in showOfenSwitch"
          :key="index"
        >
          <!-- 风扇control -->
          <div class="inner-lab">
            <span>{{ item.temInfo }}</span>
            <el-switch
              size="large"
              v-model="item.value"
              @change="usbHandler(item)"
            />
          </div>
          <!-- 图标加风扇 -->
          <div class="inner-lab">
            <!-- 开关状态, 开即可调速, 关即关闭调速 -->
            <span v-if="item.sign !== 'LED'" :class="{ rotation: item.value }"
              ><i
                class="iconfont"
                :style="{
                  color: item.value ? '#00A3FF' : '#CBCBCC',
                }"
                >&#xe65f;</i
              ></span
            >
            <!-- 灯泡 -->
            <span v-if="item.sign === 'LED'"
              ><i
                class="iconfont"
                :style="{
                  color: item.value ? '#00A3FF' : '#CBCBCC',
                }"
                >&#xe660;</i
              ></span
            >
            <div class="inner-labin" v-if="item.sign !== 'LED'">
              {{ $t("moveControl.windSpeed") }}:
              <span>{{ item.speedNum }}%</span>
            </div>
          </div>
          <!-- 控制旋钮 -->
          <div class="inner-lab" v-if="item.value && item.sign !== 'LED'">
            <div class="progress">
              <el-slider
                v-model="item.speedNum"
                @change="controlSlider($event, item.sign)"
              ></el-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { debounceFn } from "@/utils/public";
import {
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  watch,
} from "vue";
import { usePartsStore } from "../../../stores/parts";
import { CONTROL_INSTRUCTION } from "@/utils/enum/code";
import { ElMessage } from "element-plus";
import { useMainStore } from "@/stores";
import HeaderBar from "@/components/HeaderBar";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
const store10 = useMainStore();
const { proxy } = getCurrentInstance();
// 唤醒国际化
const { $t } = getCurrentInstance().proxy;
// 右侧喷嘴组件
const showBendMouth = reactive([
  {
    temInfo: $t("moveControl.printSpeed"),
    temNow: 0,
    temTarget: 0,
    company: "%",
    type: "speed",
  },
]);
// 常用开关组件
const showOfenSwitch = reactive([
  {
    temInfo: $t("moveControl.modelFengshan"),
    value: false,
    sign: "Feng",
    speedNum: 0,
  },
  {
    temInfo: $t("moveControl.qiangtiFengshan"),
    value: false,
    sign: "Qiang",
    speedNum: 0,
  },
  {
    temInfo: $t("moveControl.fuzhuFengshan"),
    value: false,
    sign: "Fuzhu",
    speedNum: 0,
  },
  {
    temInfo: $t("moveControl.lFengshan"),
    value: false,
    sign: "LED",
  },
]);
const state = reactive({
  // 折叠面板
  foldFlag: true,
  socketConnect: true,
  // 打印状态
  printStatus: 0,
  socket: null,
  echart: null,
  // 事件触发中间件
  messageCenter: null,
  // z轴偏移量
  zPianyi: 0.05,
  // X归位
  xBtnshowFlag: 0,
  // y归位
  yBtnshowFlag: 0,
  // z归位
  zBtnshowFlag: 0,
  // XYZ轴移动单位
  moveUnits: "0.1",
  // Z轴偏移单位
  moveUnitsZ: "0.005",
  // 轮询查询消息(除全部的归位按钮)
  pollingMessages: null,
  // 轮询查询消息(Z轴单归)
  pollingMessagesZ: null,
  // 轮询查询消息(上下左右)
  pollingMessagesXYZ: null,
  // 喷头温度
  nozzleTemp: store10.nozzleTemp,
});
// 监听语言切换,动态整理国际化
watch(
  () => locale.value,
  () => {
    // 后台动深层态数据手动国际化
    // console.log("status", locale.value);
    // 常用开关名称
    showOfenSwitch[0].temInfo = $t("moveControl.modelFengshan");
    showOfenSwitch[1].temInfo = $t("moveControl.fuzhuFengshan");
    showOfenSwitch[2].temInfo = $t("moveControl.qiangtiFengshan");
    showOfenSwitch[3].temInfo = $t("moveControl.lFengshan");
    // 打印速度
    showBendMouth[0].temInfo = $t("moveControl.printSpeed");
  }
);
// 当前打印机速度，监听单个属性用: watch (() => store10.curFeedratePct, (val) => {}), 不要用 watch(store10.curFeedratePct)
watch(
  () => store10.curFeedratePct,
  (val) => {
    console.log("当前打印机速度", val);
    showBendMouth[0].temNow = val;
  }
);
// 归位情况
watch(
  () => store10.autohome,
  (val) => {
    if (val) {
      // console.log("归位啊啊啊啊啊", val);
      // x 归位
      state.xBtnshowFlag = +val[0].slice(2);
      // y 归位
      state.yBtnshowFlag = +val[1].slice(2);
      // z 归位
      state.zBtnshowFlag = +val[2].slice(2);
      checkHomeReady();
    }
  }
);
// 监听获取打印状态、LED状态等
watch(
  [
    () => store10.state,
    // 模型风扇开关
    () => store10.fan,
    // 模型风扇速度
    () => store10.modelFanPct,
    // 腔体风扇开关
    () => store10.fanCase,
    // 腔体风扇速度
    () => store10.caseFanPct,
    // 辅助风扇开关
    () => store10.fanAuxiliary,
    // 辅助风扇速度
    () => store10.auxiliaryFanPct,
    // LED灯开关
    () => store10.lightSw,
  ],
  () => {
    // 处于打印中的状态不容干涉
    state.printStatus = store10.state;
    // 打印中状态不允许机器控制
    // 模拟风扇开关状态
    showOfenSwitch[0].value = store10.fan === 1 ? true : false;
    // 腔体风扇开关状态
    showOfenSwitch[1].value = store10.fanCase === 1 ? true : false;
    // 辅助风扇开关状态
    showOfenSwitch[2].value = store10.fanAuxiliary === 1 ? true : false;
    // LED灯开关状态
    showOfenSwitch[3].value = store10.lightSw === 1 ? true : false;
    // console.log("辅助风扇速度", store10.auxiliaryFanPct);
    // console.log("模型风扇速度1", store10.modelFanPct);
    // console.log("腔体风扇速度", store10.caseFanPct);
    showOfenSwitch[0].speedNum = store10.modelFanPct;
    showOfenSwitch[1].speedNum = store10.caseFanPct;
    showOfenSwitch[2].speedNum = store10.auxiliaryFanPct;
  },
  {
    immediate: true,
  }
);
// XYZ坐标监听,监听单个属性用: watch (() => store10.curPosition, (val) => {}), 不要用 watch(store10.curPosition)
watch(
  () => store10.curPosition,
  (val) => {
    // 传递给store存储当前轴坐标
    const currentPoision = {
      x: val[0].slice(2),
      y: val[1].slice(2),
    };
    usePartsStore().printCurrentPosition(currentPoision);
    // console.log("currentPoision", currentPoision);
    for (const item of showInputConfig)
      item.value = item.topInfo = val[item.index].slice(2);
  }
);
// 顶部按钮组件
const showSettingConfig = reactive([
  {
    loading: false,
    showInfo: "",
    width: "7.5%",
    height: "40px",
    disable: false,
    background: "#1E9BE2",
    iconShowInfo: "icon-a-home1",
    topPosition: "70px",
    leftPosition: "13%",
    clickAction: CONTROL_INSTRUCTION.homeXY,
    size: "14px",
    justFy: "center",
    direction: "column",
  },
  {
    showInfo: "X-",
    width: "7.5%",
    height: "40px",
    disable: false,
    background: "#4F4F54",
    iconShowInfo: "icon-a-shangjiantoukaobei3",
    topPosition: "70px",
    leftPosition: "3.5%",
    clickAction: CONTROL_INSTRUCTION.reduceX,
    size: "20px",
    justFy: "space-evenly",
    direction: "row",
  },
  {
    showInfo: "X+",
    width: "7.5%",
    height: "40px",
    disable: false,
    background: "#4F4F54",
    iconShowInfo: "icon-a-shangjiantoukaobei2",
    topPosition: "70px",
    leftPosition: "22.3%",
    clickAction: CONTROL_INSTRUCTION.addX,
    size: "11px",
    justFy: "space-evenly",
    direction: "row-reverse",
  },
  {
    showInfo: "Y+",
    width: "7.5%",
    height: "40px",
    disable: false,
    background: "#4F4F54",
    iconShowInfo: "icon-shangjiantou",
    topPosition: "20px",
    leftPosition: "13%",
    clickAction: CONTROL_INSTRUCTION.addY,
    size: "20px",
    justFy: "space-evenly",
    direction: "column",
  },
  {
    showInfo: "Y-",
    width: "7.5%",
    height: "40px",
    disable: false,
    background: "#4F4F54",
    iconShowInfo: "icon-a-shangjiantoukaobei",
    topPosition: "120px",
    leftPosition: "13%",
    clickAction: CONTROL_INSTRUCTION.reduceY,
    size: "20px",
    justFy: "space-evenly",
    direction: "column",
  },
  {
    showInfo: "Z+",
    width: "7.5%",
    height: "40px",
    disable: false,
    background: "#4F4F54",
    iconShowInfo: "icon-shangjiantou",
    topPosition: "20px",
    leftPosition: "31.5%",
    clickAction: CONTROL_INSTRUCTION.addZ,
    size: "20px",
    justFy: "space-evenly",
    direction: "column",
  },
  {
    loading: false,
    width: "7.5%",
    height: "40px",
    disable: false,
    background: "#1E9BE2",
    showInfo: "",
    iconShowInfo: "icon-a-home1",
    topPosition: "70px",
    leftPosition: "31.5%",
    clickAction: CONTROL_INSTRUCTION.homeZ,
    size: "14px",
    justFy: "center",
    direction: "column",
  },
  {
    width: "7.5%",
    height: "40px",
    disable: false,
    background: "#4F4F54",
    showInfo: "Z-",
    iconShowInfo: "icon-a-shangjiantoukaobei",
    topPosition: "120px",
    leftPosition: "31.5%",
    clickAction: CONTROL_INSTRUCTION.reduceZ,
    justFy: "space-evenly",
    size: "20px",
    direction: "column",
  },
]);
// 中间控制打印机移动幅度的数据
const showSettingMove = reactive([
  // 毫米精确
  {
    width: "10.9%",
    height: "40px",
    background: "#4F4F54",
    showInfo: "0.1",
    clickFlag: true,
    iconShowInfo: "",
    topPosition: "60px",
    leftPosition: "58.87%",
    clickAction: "showTwice",
    justFy: "space-evenly",
    size: "12px",
    direction: "row",
    itemClass: "itemClass1",
  },
  {
    width: "10.9%",
    height: "40px",
    background: "#4F4F54",
    showInfo: "1",
    clickFlag: false,
    iconShowInfo: "",
    topPosition: "60px",
    leftPosition: "72.3%",
    clickAction: "showTwice",
    justFy: "space-evenly",
    size: "12px",
    direction: "row",
    itemClass: "itemClass2",
  },
  {
    width: "10.9%",
    height: "40px",
    background: "#4F4F54",
    showInfo: "10",
    clickFlag: false,
    iconShowInfo: "",
    topPosition: "60px",
    leftPosition: "85.5%",
    clickAction: "showTwice",
    justFy: "space-evenly",
    size: "12px",
    direction: "row",
    itemClass: "itemClass3",
  },
  {
    width: "10.9%",
    height: "40px",
    background: "#4F4F54",
    showInfo: "100",
    clickFlag: false,
    iconShowInfo: "",
    topPosition: "60px",
    leftPosition: "98.6%",
    clickAction: "showTwice",
    justFy: "space-evenly",
    size: "12px",
    direction: "row",
    itemClass: "itemClass4",
  },
]);
// 中间input 配置
const showInputConfig = reactive([
  {
    index: 0,
    value: 0,
    type: "curPosition",
    firstInfo: "X",
    firstInfo1: "X",
    lastInfo: $t("moveControl.haomi"),
    // 是否可以读写: 关联X轴的归位状态
    BtnshowFlag: true,
    marginTop: "0px",
    topInfo: "40.50",
    left: "12px",
    top: "-5px",
    rate: " F3000",
  },
  {
    index: 1,
    value: 0,
    type: "curPosition",
    firstInfo: "Y",
    firstInfo1: "Y",
    lastInfo: $t("moveControl.haomi"),
    // 是否可以读写: 关联Y轴的归位状态
    BtnshowFlag: true,
    marginTop: "4px",
    topInfo: "40.50",
    left: "12px",
    top: "45px",
    rate: " F3000",
  },
  {
    index: 2,
    value: 0,
    type: "curPosition",
    firstInfo: "Z",
    firstInfo1: "Z",
    lastInfo: $t("moveControl.haomi"),
    // 是否可以读写: 关联Z轴的归位状态
    BtnshowFlag: true,
    marginTop: "4px",
    topInfo: "40.50",
    left: "12px",
    top: "95px",
    rate: " F600",
  },
]);
// 折叠面板
const foldThePage = () => {
  // console.log("准备缩小面板啦");
  state.foldFlag = false;
};
// 展开面板
const openThePage = () => {
  // console.log("准备展开面板啦");
  state.foldFlag = true;
};
function onInputFocus(item) {
  item.firstInfo = "";
}
function onInputBlur(item) {
  item.firstInfo = item.firstInfo1 + ":";
}
// 轴向坐标改变
function onInputChange(item) {
  // 如果处于打印中状态, 不允许改变轴向运动目标值
  if (state.printStatus === 1) {
    ElMessage({
      showClose: true,
      message: $t("moveControl.printWarning"),
      type: "warning",
    });
    showInputConfig[0].firstInfo = "X";
    showInputConfig[1].firstInfo = "Y";
    showInputConfig[2].firstInfo = "Z";
    showInputConfig[0].BtnshowFlag = false;
    showInputConfig[1].BtnshowFlag = false;
    showInputConfig[2].BtnshowFlag = false;
    return;
  }
  if (Number(item.firstInfo) - Number(item.topInfo) == 0) {
    ElMessage({
      showClose: true,
      message: $t("moveControl.shuruMubiao"),
      type: "warning",
    });
    return;
  }
  alert(2);
  // 类似于上下左右(目标-当前)差值
  state.socket.sendMsg({
    method: "set",
    params: {
      // "XYZ + (目标值 - 当前值)"
      setPosition:
        item.firstInfo1 +
        (Number(item.firstInfo) - Number(item.topInfo)) +
        item.rate,
    },
  });
  // 3秒周期内关掉轮询
  setTimeout(() => {
    clearInterval(state.pollingMessagesInput);
    // console.log("关闭获取轴坐标的目标值轮询");
  }, 3000);
}
// 风扇速度控制
function controlSlider(val, flag) {
  let valStyle = 255 * (val / 100);
  switch (flag) {
    // 模型风扇
    case "Feng":
      state.socket.sendMsg({
        method: "set",
        params: { gcodeCmd: "M106 P0 S" + Math.round(valStyle) },
      });
      break;
    // 腔体风扇
    case "Qiang":
      state.socket.sendMsg({
        method: "set",
        params: { gcodeCmd: "M106 P1 S" + Math.round(valStyle) },
      });
      break;
    // 辅助风扇
    case "Fuzhu":
      state.socket.sendMsg({
        method: "set",
        params: { gcodeCmd: "M106 P2 S" + Math.round(valStyle) },
      });
      break;
    default:
      break;
  }
}
// 风扇开关
function usbHandler(item) {
  // 模型风扇开关
  if (item.sign === "Feng") {
    state.socket.sendMsg({
      method: "set",
      params: { fan: +item.value },
    });
  }
  // 腔体风扇开关
  if (item.sign === "Qiang") {
    state.socket.sendMsg({
      method: "set",
      params: { fanCase: +item.value },
    });
  }
  // 辅助风扇开关
  if (item.sign === "Fuzhu") {
    state.socket.sendMsg({
      method: "set",
      params: { fanAuxiliary: +item.value },
    });
  }
  if (item.sign === "LED") {
    // 开启LED灯, 参数调整
    state.socket.sendMsg({
      method: "set",
      params: { lightSw: +item.value },
    });
  }
}
// 轮询发消息挂载打印机状态数据
// function getDataHome() {
//   state.pollingMessagesTop = setInterval(function () {
//     // console.log("首页轮询两秒数据更新", new Date());
//     state.socket.sendMsg({
//       method: "get",
//       params: { ReqPrinterPara: 1 },
//     });
//   }, 2000);
// }
// 调取socket指令控制喷嘴、热床、速度
const temperHandler = (type, val) => {
  if (type === "speed") {
    state.socket.sendMsg({
      method: "set",
      params: { setFeedratePct: +val },
    });
  }
};
// 回抽和挤出: 喷头温度大于180°时才能点击
// function retractStretch(item) {
//   // 回抽
//   if (item.clickAction == CONTROL_INSTRUCTION.goBack) {
//     // console.log("这是回抽");
//     state.socket.sendMsg({
//       method: "set",
//       params: {
//         setPosition: "E-20 F3000",
//       },
//     });
//     // 挤出
//   } else {
//     // console.log("这是挤出");
//     state.socket.sendMsg({
//       method: "set",
//       params: {
//         setPosition: "E20 F3000",
//       },
//     });
//   }
// }
// 封装打印机防抖函数3.15
const debounceGo = debounceFn((item) => {
  // console.log("机器控制防抖");
  // 如果处于打印中的状态,不允许控制打印机
  if (state.printStatus === 1) {
    ElMessage({
      showClose: true,
      message: $t("moveControl.printWarning"),
      type: "warning",
    });
    return;
  }
  // // 回抽和挤出功能转移
  // if (
  //   item.clickAction == CONTROL_INSTRUCTION.goBack ||
  //   item.clickAction == CONTROL_INSTRUCTION.goOut
  // ) {
  //   // 温度小于180度不能进行回抽和挤出
  //   if (state.nozzleTemp < 180) {
  //     return;
  //   }
  //   retractStretch(item);
  //   return;
  // }
  if (!state.socketConnect) {
    ElMessage({
      showClose: true,
      message: $t("configuration.closeWarn"),
      type: "warning",
    });
    return;
  }
  // 上下左右按钮置灰不让点击: 请先将方位按钮归位
  if (item.disable === true) {
    ElMessage({
      message: $t("configuration.guiweiPrint"),
      type: "warning",
    });
    return;
  }
  // 优先归位XY
  if (
    item.clickAction == CONTROL_INSTRUCTION.homeZ &&
    showSettingConfig[0].background != "#4F4F54"
  ) {
    ElMessage({
      showClose: true,
      message: $t("configuration.guiweiPrint"),
      type: "warning",
    });
    return;
  }
  // 归位按钮
  if (item.loading === false) {
    item.loading = true;
    // 非全部归位用同一个轮询,全部归位单独一个轮询,避免轮询覆盖
    if (item.showInfo !== $t("configuration.allIt")) {
      if (state.pollingMessages !== null) {
        return;
      }
      // 轮询发消息
      state.pollingMessages = setInterval(function () {
        // console.log("轮询去执行指令,完成后清除定时器", state.pollingMessages);
        state.socket.sendMsg({
          method: "get",
          params: { ReqPrinterPara: 1 },
        });
      }, 1000);
    } else {
      // 轮询发消息
      state.pollingMessagesZ = setInterval(function () {
        // console.log("全部归位的轮询阿", state.pollingMessagesZ);
        state.socket.sendMsg({
          method: "get",
          params: { ReqPrinterPara: 1 },
        });
      }, 1000);
    }
  } else {
    if (state.pollingMessagesXYZ !== null) {
      return;
    }
    // 轮询发消息,直到XYZ的轴向位置变动
    state.pollingMessagesXYZ = setInterval(function () {
      // console.log("直到XYZ的轴向位置变动", state.pollingMessagesXYZ);
      state.socket.sendMsg({
        method: "get",
        params: { ReqPrinterPara: 1 },
      });
    }, 1000);
    // 5秒周期内关掉轮询
    setTimeout(() => {
      clearInterval(state.pollingMessagesXYZ);
      state.pollingMessagesXYZ = null;
      // console.log("关掉上下左右轮询");
    }, 3000);
  }
  console.log("item.clickAction是啥呀", item.clickAction);
  // 去触发指令
  switch (item.clickAction) {
    case CONTROL_INSTRUCTION.homeXY:
      // XY归位
      state.socket.sendMsg({
        method: "set",
        params: { autohome: "X Y" },
      });
      break;
    case CONTROL_INSTRUCTION.homeX:
      // X归位
      state.socket.sendMsg({
        method: "set",
        params: { autohome: "X" },
      });
      break;
    case CONTROL_INSTRUCTION.homeY:
      // Y归位
      state.socket.sendMsg({
        method: "set",
        params: { autohome: "Y" },
      });
      break;
    case CONTROL_INSTRUCTION.homeZ:
      // z归位
      state.socket.sendMsg({
        method: "set",
        params: { autohome: "Z" },
      });
      break;
    case CONTROL_INSTRUCTION.homeXYZ:
      // XYZ归位
      state.socket.sendMsg({
        method: "set",
        params: { autohome: "X Y Z" },
      });
      break;
    case CONTROL_INSTRUCTION.reduceX:
      // X减
      console.log("showInputConfig", showInputConfig[0].topInfo);
      if (+showInputConfig[0].topInfo == -5) {
        ElMessage({
          showClose: true,
          message: $t("configuration.assetDi"),
          type: "warning",
        });
        return;
      }
      alert(1);
      // 发送X-具体指令
      state.socket.sendMsg({
        method: "set",
        params: { setPosition: "X-" + state.moveUnits + " F3000" },
      });
      break;
    case CONTROL_INSTRUCTION.addX:
      // X加
      console.log("state.moveUnits", state.moveUnits);
      state.socket.sendMsg({
        method: "set",
        params: { setPosition: "X" + state.moveUnits + " F3000" },
      });
      break;
    case CONTROL_INSTRUCTION.addY:
      // Y加
      console.log("Y加++++++++", state.moveUnits);
      state.socket.sendMsg({
        method: "set",
        params: { setPosition: "Y" + state.moveUnits + " F3000" },
      });
      break;
    case CONTROL_INSTRUCTION.reduceY:
      // Y减
      if (+showInputConfig[1].topInfo == -5) {
        ElMessage({
          showClose: true,
          message: $t("configuration.assetDi"),
          type: "warning",
        });
        return;
      }
      state.socket.sendMsg({
        method: "set",
        params: { setPosition: "Y-" + state.moveUnits + " F3000" },
      });
      break;
    case CONTROL_INSTRUCTION.reduceZ:
      // Z减, 热床减
      state.socket.sendMsg({
        method: "set",
        params: { setPosition: "Z" + state.moveUnits + " F600" },
      });
      break;
    case CONTROL_INSTRUCTION.addZ:
      // Z加,热床加
      state.socket.sendMsg({
        method: "set",
        params: { setPosition: "Z-" + state.moveUnits + " F600" },
      });
      break;
    case CONTROL_INSTRUCTION.bottomZ:
      // console.log("Z下");
      // Z下
      state.socket.sendMsg({
        method: "set",
        params: { setZOffset: "-" + state.moveUnitsZ },
      });
      break;
    case CONTROL_INSTRUCTION.topZ:
      // console.log("Z上");
      // Z上
      state.socket.sendMsg({
        method: "set",
        params: { setZOffset: "+" + state.moveUnitsZ },
      });
      break;
    case CONTROL_INSTRUCTION.saveZ:
      // console.log("保存配置");
      state.socket.sendMsg({
        method: "set",
        params: { savePara: 1 },
      });
      break;
    default:
      break;
  }
}, 1500);
// 毫米级别的打印机位置移动预设
function setPrintmove(item, dataList) {
  state.moveUnits = item.showInfo;
  for (let ele = 0; ele < dataList.length; ele++) {
    if (item.showInfo !== dataList[ele].showInfo) {
      dataList[ele].clickFlag = false;
    }
  }
  item.clickFlag = true;
}
// 是否归位的情况考虑
function checkHomeReady() {
  if (state.xBtnshowFlag === 1) {
    // X-归位
    showSettingConfig[1].background = "#1E9BE2";
    // X+归位
    showSettingConfig[2].background = "#1E9BE2";
    showSettingConfig[1].disable = false;
    showSettingConfig[2].disable = false;
  } else {
    // X 未归位: xy归位按钮高亮
    if (state.printStatus !== 1) {
      showSettingConfig[0].background = "#1E9BE2";
    }
    // X-归位
    showSettingConfig[1].background = "#4F4F54";
    // X+归位
    showSettingConfig[2].background = "#4F4F54";
    // X-不能点击
    showSettingConfig[1].disable = true;
    // X+不能点击
    showSettingConfig[2].disable = true;
  }
  // 实时更新XY归位的状态[2]
  if (state.yBtnshowFlag === 1) {
    // Y+归位
    showSettingConfig[3].background = "#1E9BE2";
    // Y-归位
    showSettingConfig[4].background = "#1E9BE2";
    showSettingConfig[3].disable = false;
    showSettingConfig[4].disable = false;
  } else {
    // Y 未归位: xy归位按钮高亮
    if (state.printStatus !== 1) {
      showSettingConfig[0].background = "#1E9BE2";
    }
    // Y+归位
    showSettingConfig[3].background = "#4F4F54";
    // Y-归位
    showSettingConfig[4].background = "#4F4F54";
    showSettingConfig[3].disable = true;
    showSettingConfig[4].disable = true;
  }
  // 实时更新Z轴归位的状态
  if (state.zBtnshowFlag === 1) {
    // Z+归位
    showSettingConfig[5].background = "#1E9BE2";
    // Z-归位
    showSettingConfig[7].background = "#1E9BE2";
    // Z轴的归位Home置灰
    showSettingConfig[6].background = "#4F4F54";
    showSettingConfig[5].disable = false;
    showSettingConfig[7].disable = false;
    // 输入框改变轴位置可用
    showInputConfig[2].BtnshowFlag = false;
    setTimeout(() => {
      showSettingConfig[6].loading = false;
    }, 1200);
  } else {
    // Y+归位
    showSettingConfig[5].background = "#4F4F54";
    // Y-归位
    showSettingConfig[7].background = "#4F4F54";
    // Y轴的归位Home高亮
    showSettingConfig[6].background = "#1E9BE2";
    showSettingConfig[5].disable = true;
    showSettingConfig[7].disable = true;
    // 输入框改变轴位置不可用
    showInputConfig[0].BtnshowFlag = true;
  }
  // XY归位按钮回显
  if (state.xBtnshowFlag === 1 && state.yBtnshowFlag === 1) {
    // console.log("XY归位啦");
    // 输入框改变轴位置可用
    showInputConfig[0].BtnshowFlag = false;
    // 输入框改变轴位置可用
    showInputConfig[1].BtnshowFlag = false;
    // X-归位
    showSettingConfig[0].background = "#4F4F54";
    clearInterval(state.pollingMessages);
    state.pollingMessages = null;
    setTimeout(() => {
      showSettingConfig[0].loading = false;
    }, 1200);
  }
  // XYZ归位按钮回显
  if (
    state.xBtnshowFlag === 1 &&
    state.yBtnshowFlag === 1 &&
    state.zBtnshowFlag === 1
  ) {
    // console.log("XYZ归位啦");
    // 输入框改变轴位置可用
    showInputConfig[2].BtnshowFlag = false;
    clearInterval(state.pollingMessagesZ);
    state.pollingMessagesZ = null;
  } else {
    // 全部 归位
    // showSettingConfig[8].background = "#1E9BE2";
  }
}
onUnmounted(() => {
  clearInterval(state.pollingMessagesTop);
  state.pollingMessagesZ = null;
});
onMounted(() => {
  state.socket = proxy.$webSocket;
  state.echart = proxy.$echarts;
  state.messageCenter = proxy.$messageCenter;
  // 初始挂载数据: 归位数据等(3.21 轮询方案改为后台主动发消息给前台。)
  // getDataHome();
  // websocket处于待命状态，非必要不要进行消息发送操作
  // state.messageCenter.on("websocket_open", (data) => {
  //   // websocket服务处于待命状态,可以发指令,先把打印机的轴位置,复位按钮标识搞过来
  //   if (data == 1) {
  //   }
  // });
  state.messageCenter.on("socket_close", () => {
    // console.log("socket服务处于关闭状态");
    if (!state.socketConnect) return;
    state.socketConnect = false;
    ElMessage({
      showClose: true,
      message: $t("configuration.closeWarn"),
      type: "warning",
    });
    // 关掉所有定时器
    clearInterval(state.pollingMessagesXYZ);
    clearInterval(state.pollingMessages);
    clearInterval(state.pollingMessagesZ);
    clearInterval(state.pollingMessagesInput);
  });
});
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/PrintSetting.scss";
</style>
