<template>
  <g id="parts">
    <g
      v-for="name in parts"
      :key="name"
      :class="[
        iconClasses(name),
        props.lightObjects.includes(name) ? 'partsEcclude' : '',
      ]"
      class="layer"
    >
      <!-- svg外部秒变图形路径 -->
      <path
        v-if="!props.lightObjects.includes(name)"
        class="partOutline"
        :d="partSVG(name)"
      />
      <!-- 核心原型状态的svg图标路径，加入click事件，用于图标点击事件传递 -->
      <!-- 版本一: 灰色禁止符号 -->
      <!-- <svg
        width="20"
        height="20"
        viewBox="0 0 30 30"
        class="partIcon"
        :x="partPos(name).x - 18 / 2"
        :y="partPos(name).y - 12 / 2"
      >
        <path :d="iconCancelled" />
        <path
          :d="iconCircle"
          class="hitarea"
          @click="handleTouchEnd(name)"
          @touchend="handleTouchEnd(name)"
        />
      </svg> -->
      <svg
        t="1695106316480"
        class="partIcon"
        :viewBox="solveFun(name)"
        viewBox="0 0 1200 1200"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        :x="partPos(name).x - 18 / 2"
        :y="partPos(name).y - 12 / 2"
        width="25"
        height="25"
      >
        <path
          v-if="!props.lightObjects.includes(name)"
          class="hitarea"
          @click="handleTouchEnd(name)"
          @touchend="handleTouchEnd(name)"
          :fill="partsObject[name].color"
          d="M512.021333 484.565333a44.437333 44.437333 0 0 1-17.770666-4.394666l-225.002667-100.48c-18.922667-8-27.434667-29.034667-18.986667-46.976 3.797333-8.085333 10.56-14.485333 18.986667-18.026667l213.845333-96a71.210667 71.210667 0 0 1 57.728 0l213.930667 96c18.901333 8 27.413333 29.056 18.986667 46.997333a36.416 36.416 0 0 1-18.986667 18.005334l-225.024 100.394666c-5.461333 2.752-11.541333 4.266667-17.706667 4.48zM453.930667 810.666667c-5.781333 0-11.477333-1.365333-16.576-3.946667l-203.285334-90.453333c-12.906667-6.421333-20.885333-19.093333-20.736-32.874667v-230.613333a36.608 36.608 0 0 1 19.072-31.488 37.12 37.12 0 0 1 35.008 0l202.901334 90.368c12.245333 6.272 20.074667 18.304 20.586666 31.488v230.912a36.885333 36.885333 0 0 1-18.901333 31.488c-5.461333 3.114667-11.690667 4.906667-18.069333 5.12z m116.138666 0c-6.293333 0-12.544-1.578667-18.090666-4.522667a37.013333 37.013333 0 0 1-18.901334-31.509333V543.082667c0.298667-13.12 7.893333-25.088 19.904-31.488l204.266667-90.965334a36.906667 36.906667 0 0 1 34.346667 0.789334c11.776 6.570667 18.986667 18.538667 19.072 31.509333v230.464a36.394667 36.394667 0 0 1-19.904 32.874667l-204.053334 90.517333a35.797333 35.797333 0 0 1-16.64 3.882667z"
        ></path>
        <!-- 已经被排除的对象 -->
        <path
          class="eventNone"
          v-if="props.lightObjects.includes(name)"
          d="M0 1024V0h1024v1024H0zM981.333333 42.666667H42.666667v938.666666h938.666666V42.666667z"
          p-id="4170"
        ></path>
        <path
          class="eventNone"
          v-if="props.lightObjects.includes(name)"
          d="M512.021333 484.565333a44.437333 44.437333 0 0 1-17.770666-4.394666l-225.002667-100.48c-18.922667-8-27.434667-29.034667-18.986667-46.976 3.797333-8.085333 10.56-14.485333 18.986667-18.026667l213.845333-96a71.210667 71.210667 0 0 1 57.728 0l213.930667 96c18.901333 8 27.413333 29.056 18.986667 46.997333a36.416 36.416 0 0 1-18.986667 18.005334l-225.024 100.394666c-5.461333 2.752-11.541333 4.266667-17.706667 4.48zM453.909333 810.666667c-5.76 0-11.456-1.365333-16.554666-3.946667l-203.285334-90.453333c-12.906667-6.421333-20.885333-19.114667-20.736-32.874667v-230.613333a36.608 36.608 0 0 1 19.072-31.488 37.12 37.12 0 0 1 35.008 0l202.922667 90.368c12.224 6.272 20.053333 18.304 20.565333 31.488v230.912a36.906667 36.906667 0 0 1-18.901333 31.509333 39.552 39.552 0 0 1-18.090667 5.098667z m116.16 0c-6.293333 0-12.544-1.578667-18.069333-4.522667a36.992 36.992 0 0 1-18.922667-31.509333V543.082667c0.298667-13.12 7.893333-25.088 19.904-31.488l204.266667-90.965334a36.970667 36.970667 0 0 1 34.346667 0.789334c11.776 6.570667 18.986667 18.538667 19.072 31.509333v230.464a36.373333 36.373333 0 0 1-19.904 32.874667l-204.053334 90.517333a35.797333 35.797333 0 0 1-16.64 3.882667z"
          p-id="4171"
        ></path>
        <path
          class="eventNone"
          v-if="props.lightObjects.includes(name)"
          d="M42.666667 960L960 42.666667l21.333333 21.333333L64 981.333333l-21.333333-21.333333z"
          p-id="4172"
          fill="#FD265A"
          stroke-width="10"
        ></path>
      </svg>
    </g>
  </g>
</template>

<script setup>
// defineProps
import {
  computed,
  ref,
  reactive,
  watch,
  defineEmits,
  getCurrentInstance,
  onMounted,
  defineProps,
  defineExpose,
} from "vue";
import { usePartsStore } from "../../../../stores/parts";
import { useMainStore } from "@/stores";
const store10 = useMainStore();
// 定义子组件的收发装置
const props = defineProps(["lightObjects"]);
// 定义要发送的emits事件。
const emits = defineEmits(["partChecked"]);
const touchedElement = ref("");
let isPartsChecked = ref("");
let state = reactive({
  forceUpdate: () => {},
});
const partsObject = usePartsStore().getParts;
const excluded_erti_data = Object.values(partsObject);
// 点击核心svg图标的事件触发入口
const handleTouchEnd = (name) => {
  touchedElement.value = name;
  isPartsChecked.value = name;
  emits("partChecked", name);
};
// 子组件暴露对象，方法，父组件去改变子组件的data
defineExpose({
  isPartsChecked,
});
// 核心svg路径数据
const parts = computed(() => {
  const parts = usePartsStore().getParts;
  return Object.keys(parts);
});
// 被排除的对象单独用svg，path描绘，比例自定义
const solveFun = function (name) {
  if (props.lightObjects.includes(name)) {
    return `0 0 1200 1200`;
  } else {
    return `70 210 1200 1200`;
  }
};
// 排除、未排除的对象的类型添加
const iconClasses = (name) => {
  return isPartExcluded(name)
    ? "partExcluded"
    : isPartCurrent(name)
    ? "partCurrent"
    : "partIncluded";
};
// 实时监听当前打印对象，和打印机的轴坐标实时比对，然后动态显示当前打印情况
watch(
  () => store10.curPosition,
  (val) => {
    // 传递给store存储当前轴坐标
    const currentPoision = {
      x: parseInt(val[0].slice(2)),
      y: parseInt(val[1].slice(2)),
    };
    excluded_erti_data.forEach((element) => {
      partsObject[element.name].color = "";
      if (
        Math.abs(currentPoision.x - parseInt(element.target.x)) <= 20 &&
        Math.abs(currentPoision.y - parseInt(element.target.y)) <= 20
      ) {
        partsObject[element.name].color = "#FFC100";
        state.forceUpdate();
      }
    });
  },
  {
    immediate: true,
  }
);
const partSVG = (name) => {
  return usePartsStore().getPartSVG(name);
};

const partPos = (name) => {
  return usePartsStore().getPartPos(name);
};

const isPartCurrent = (name) => {
  return usePartsStore().getIsPartCurrent(name);
};

const isPartExcluded = (name) => {
  return usePartsStore().getIsPartExcluded(name);
};
onMounted(() => {
  const { proxy } = getCurrentInstance();
  state.forceUpdate = proxy.$forceUpdate;
});
</script>

<style lang="scss" scoped>
.layer {
  cursor: pointer;
  filter: brightness(100%);

  // & > path {
  //   stroke-linecap: round;
  //   stroke-linejoin: round;
  // }

  .partOutline {
    filter: opacity(60%);
    stroke-width: 1;
  }
  // 常规打印的时候图标背景色
  .partIcon {
    fill-opacity: 100%;
    stroke-width: 15;
    // svg填充颜色
    fill: #d2d2da;
    .hitarea {
      pointer-events: all;
      z-index: -1;
      transform-origin: center !important;
      transform: rotate(180deg) rotateY(180deg) translateY(150px) !important;
    }
    .eventNone {
      pointer-events: none;
    }
  }
  // 被排除的对象不参与事件
  &.partExcluded {
    pointer-events: all;
  }

  &.partIncluded {
    fill: transparent;
    stroke: darkgray;
  }
  &.partIncluded.partsEcclude,
  &.partCurrent.partsEcclude {
    // fill: #ffc100;
    stroke: #6d6d74;
    .partIcon {
      fill: #6d6d74;
    }
  }
  &.partCurrent {
    fill: transparent;
    stroke: #00f4c3;
  }
}
.layer:hover {
  .partOutline {
    stroke: #1e9be2;
    fill-opacity: 100%;
  }
}
// 被排除的对象hover
.partsEcclude:hover {
  .partOutline {
    stroke: #6d6d74;
  }
}
// 排除类的hover，鼠标悬停的css写法
.theme--light .layer {
  filter: brightness(90%);
}
</style>
