import { usePrepareStore } from "./prepare";
import { useConfigStore } from "./configData";
import { defineStore } from "pinia";
const useMainStore = defineStore("mainStore", {
  state: () => {
    return {};
  },
});

export { usePrepareStore, useConfigStore, useMainStore };
