<template>
  <div class="home">
    <div class="head-bar">
      <div class="bar-left">
        <div class="logo" @click.stop="goHome">
          <img src="../../public/mylogo.png" alt="" />
          <span>{{ $t("configuration.crealityPrint") }}</span>
        </div>
      </div>
      <div class="bar-right">
        <!-- 国际化切换 -->
        <div class="interna">
          <i class="iconfont" @click.stop="changeSlect">&#xe65e;</i>
          <el-select
            v-model="value"
            effect="dark"
            class="m-2"
            :placeholder="state.selectVal"
            @change="selectChange"
          >
            <el-option
              v-for="item in state.languageOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <!-- 重启服务 -->
        <i class="iconfont" @click="restartService">&#xe65b;</i>
      </div>
    </div>
    <!-- 路由形式管控组件 -->
    <router-view />
  </div>
</template>
<script setup>
// import { reactive } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, getCurrentInstance, onMounted } from "vue";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
// 唤醒国际化
const { $t } = getCurrentInstance().proxy;
let state = reactive({
  socket: null,
  // headarWidth: "98%",
  // 国际化切换
  languageOption: [
    { value: 0, label: "简体中文" },
    { value: 1, label: "English" },
  ],
  selectVal: "English",
});
onMounted(() => {
  const { proxy } = getCurrentInstance();
  state.socket = proxy.$webSocket;
});
const router = useRouter();
// const menuConfig = reactive({
//   data: [
//     {
//       name: "首页",
//       icon: "",
//       page: "Home",
//       active: true,
//       iconShowInfo: "icon-a-xingzhuang886",
//       size: "16px",
//     },
//     {
//       name: "准备和调整",
//       icon: "",
//       page: "PrepareAdjust",
//       active: false,
//       iconShowInfo: "icon-a-xingzhuang13",
//       size: "16px",
//     },
//     {
//       name: "打印历史记录",
//       icon: "",
//       page: "PrintHistory",
//       active: false,
//       iconShowInfo: "icon-a-xingzhuang14",
//       size: "16px",
//     },
//     {
//       name: "设备管理及信息",
//       icon: "",
//       page: "DeviceManagement",
//       active: false,
//       iconShowInfo: "icon-copyPrp",
//       size: "16px",
//     },
//     {
//       name: "配置管理",
//       icon: "",
//       page: "ConfigManagement",
//       active: false,
//       iconShowInfo: "icon-copySet",
//       size: "16px",
//     },
//     {
//       name: "Gcode预览",
//       icon: "",
//       page: "GcodePreview",
//       active: false,
//       iconShowInfo: "icon-gcodeIcon",
//       size: "16px",
//     },
//   ],
// });
// 路由监控,刷新返回首页
if (router.path !== "/home") {
  router.replace("home");
}
function restartService() {
  // 头部样式优化
  // state.headarWidth = "97.05%";
  // 配置保存交互提示
  ElMessageBox.confirm(
    $t("configuration.resetFuwu"),
    $t("configuration.Tips"),
    {
      confirmButtonText: $t("configuration.sureInfo"),
      cancelButtonText: $t("configuration.cancel"),
      type: "warning",
    }
  )
    .then(() => {
      // 重启Klipper
      state.socket.sendMsg({
        method: "set",
        params: { restartKlipper: 1 },
      });
      // 重启下位机
      state.socket.sendMsg({
        method: "set",
        params: { restartFirmware: 1 },
      });
      ElMessage({
        type: "success",
        message: $t("configuration.resetSuccess"),
      });
      // state.headarWidth = "98%";
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: $t("configuration.quxiao"),
      });
      // setTimeout(() => {
      //   state.headarWidth = "98%";
      // }, 200);
    });
}
// function changePages(params, dataList) {
//   params.active = true;
//   // 模块Tab切换
//   for (let ele = 0; ele < dataList.length; ele++) {
//     if (params.page !== dataList[ele].page) {
//       dataList[ele].active = false;
//     }
//   }
//   // 模块路由切换
//   switch (params.page) {
//     case "Home":
//       router.push("/home");
//       break;
//     case "PrepareAdjust":
//       router.push("/prepare");
//       break;
//     case "PrintHistory":
//       router.push("/printHistory");
//       break;
//     case "DeviceManagement":
//       router.push("/deviceManagement");
//       break;
//     case "ConfigManagement":
//       router.push("/configManagement");
//       break;
//     case "GcodePreview":
//       router.push("/gcodePreview");
//       break;
//     default:
//       break;
//   }
// }
// 动态changeSlect事件
function changeSlect() {
  const elePopper = document.getElementsByClassName("el-popper")[0];
  console.log("elePopper", elePopper.style.display);
  if (elePopper.style.display === "none") {
    elePopper.style.display = "block";
  } else {
    elePopper.style.display = "none";
  }
}
function selectChange(val) {
  // console.log("选的啥", val);
  if (val === 0) {
    state.selectVal = "简体中文";
    locale.value = "zh";
  }
  if (val === 1) {
    state.selectVal = "English";
    locale.value = "en";
  }
  const elePopper = document.getElementsByClassName("el-popper")[0];
  elePopper.style.display = "none";
}
// 返回首页
function goHome() {
  router.push("/home");
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .home {
    min-width: 540px !important;
  }
}
.home {
  width: 100%;
  height: 100%;
  // 兼容移动设备
  @media screen and (max-width: 1200px) {
    .head-bar {
      width: 98% !important;
    }
  }
  .head-bar {
    height: 56px;
    background: #1c1c1d;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    position: fixed;
    z-index: 999;
    width: 1880px;
    // width: 98%;
    .bar-left {
      display: flex;
      align-items: center;
      height: 100%;
      .logo {
        img {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
        span {
          vertical-align: super;
          padding-left: 10px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #d2d2d8;
        }
      }
      .line {
        width: 1px;
        height: 20px;
        background: #4b4b50;
        margin: 0 20px;
      }
      .menu {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          padding: 0 20px;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          .tab-span {
            padding-left: 6px;
            color: #b4b4bb;
          }
          .iconfont {
            color: #b4b4bb;
          }
          &:hover {
            background: #343435;
            border-radius: 5px;
            height: 44px;
          }
        }
        .is-active {
          background: $theme;
          height: 44px;
          border-radius: 5px;
          .iconfont {
            color: $white;
          }
          .tab-span {
            color: $white;
          }
        }
      }
    }
    .bar-right {
      font-size: 30px;
      height: 100%;
      writing-mode: vertical-lr;
      margin-right: 30px;
      cursor: pointer;
      .iconfont {
        color: #b4b4bb;
        font-size: 20px;
        &:hover {
          color: #fff;
        }
      }
      .interna {
        padding: 11px 40px 0px 0px;
        writing-mode: horizontal-tb;
        :deep() .el-select {
          --el-select-input-focus-border-color: none !important;
          --el-select-border-color-hover: none !important;
          .select-trigger {
            .el-input {
              width: 105px;
              --el-input-border: none !important;
            }
            .el-input:focus-within {
              border: none;
              .el-input__wrapper {
                .el-input__inner {
                  color: #fff !important;
                }
                // .el-input__suffix {
                //   .el-input__suffix-inner {
                //     .el-icon {
                //       margin-left: -40px;
                //     }
                //   }
                // }
              }
            }
          }
        }
      }
    }
  }
  .content-page {
    padding: 21px;
  }
}
</style>

