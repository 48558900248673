<template>
  <div
    class="comp-CameraShow"
    :style="{ height: accessible ? '539px' : '539px' }"
    :class="{ controlAnimal: !state.foldFlag }"
  >
    <HeaderBar :title="$t('cameraModule.cameraShow')" :icon="'&#xe61e;'">
      <template v-slot:right>
        <!-- 收起面板 -->
        <div class="fold">
          <!-- 折叠 -->
          <i class="iconfont" @click.stop="foldThePage" v-if="state.foldFlag"
            >&#xe65a;</i
          >
          <!-- 展开 -->
          <i class="iconfont" @click.stop="openThePage" v-else>&#xe659;</i>
        </div>
      </template>
    </HeaderBar>
    <div class="content" v-show="state.foldFlag">
      <!-- 网鱼客服系统测试iframe -->
      <!-- <iframe
        ref="camera_image"
        src="http://as.cxswyjy.com/slice/#/OnlineSupport?serverEnv=1&language=en"
        class="camera-image"
        frameborder="0"
        scrolling="no"
        v-if="true"
      /> -->
      <!-- img的形式提升推流稳定性3.22 -->
      <img
        ref="camera_image"
        :src="url"
        class="camera-image"
        v-if="accessible"
      />
      <div class="netErr" v-else>
        <div class="iconfont">&#xe601;</div>
        <p>
          {{ $t("cameraModule.pleaseCheck") }} {{ ip }}
          {{ $t("cameraModule.printAsset") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeaderBar from "@/components/HeaderBar";
import { onMounted, getCurrentInstance, ref, reactive } from "vue";
const url = ref("");
const ip = ref("");
const accessible = ref(true);
let state = reactive({
  // 折叠面板
  foldFlag: true,
});
// 折叠面板
const foldThePage = () => {
  // console.log("准备缩小面板啦");
  state.foldFlag = false;
};
// 展开面板
const openThePage = () => {
  // console.log("准备展开面板啦");
  state.foldFlag = true;
};
const checkNetErr = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};
onMounted(async () => {
  const { proxy } = getCurrentInstance();
  url.value = `http://${proxy.$ip}:8080/?action=stream`;
  // console.log("摄像头资源是啥", url.value);
  ip.value = proxy.$ip;
  accessible.value = await checkNetErr(url.value);
});
</script>
<style lang="scss" scoped>
.controlAnimal {
  height: 40px !important;
}
// 兼容移动设备
@media screen and (max-width: 1200px) {
  .comp-CameraShow {
    // height: 25rem;
    :deep() .com-HeaderBar {
      .firstDiv {
        width: 7rem !important;
      }
    }
    :deep() .content {
      height: 20rem;
    }
  }
}
.comp-CameraShow {
  width: 100%;
  width: 100%;
  @include theme_layout();
  .fold {
    cursor: pointer;
    .iconfont {
      color: #d2d2da;
      font-size: 8px;
    }
  }
  .content {
    padding: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 90px);
    .camera-image {
      width: 100%;
      height: 100%;
    }
    .netErr {
      width: 100%;
      height: 100%;
      background: #29292c;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: #b1b1b9;
      .iconfont {
        font-size: 50px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
