<template>
  <div class="control-main" :class="{ controlAnimal: !state.foldFlag }">
    <!-- 头部容器 -->
    <HeaderBar
      :title="$t('configuration.paichuObject')"
      class="header"
      :icon="'&#xe661;'"
    >
      <template v-slot:right>
        <!-- 收起面板 -->
        <div class="fold">
          <!-- 折叠 -->
          <i class="iconfont" @click.stop="foldThePage" v-if="state.foldFlag"
            >&#xe65a;</i
          >
          <!-- 展开 -->
          <i class="iconfont" @click.stop="openThePage" v-else>&#xe659;</i>
        </div>
      </template>
    </HeaderBar>
    <!-- 排出对象主体容器 k1device.png -->
    <div
      class="middleContent"
      :style="{ 'background-image': `url(${getImageUrl()})` }"
      v-if="state.foldFlag"
    >
      <AppFocusableContainer
        ref="container"
        @focus="focused = true"
        @blur="focused = false"
      >
        <svg
          ref="svgElement"
          :viewBox="state.viewBoxInt"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <!-- v-if="state.excluded_data.length > 0" -->
          <!-- 自调整 "-->
          <ExcludeObject
            v-if="state.excluded_data.length > 0"
            @click.stop="excludeObjectFun(state.partCheckedData)"
            @partChecked="partCheckedFun"
            :lightObjects="state.lightObjects"
            ref="excludeObject"
          />
        </svg>
      </AppFocusableContainer>

      <!-- 排除对象主容器 -->
    </div>
  </div>
</template>
<script setup>
import {
  reactive,
  onMounted,
  watch,
  getCurrentInstance,
  ref,
  onBeforeUnmount,
} from "vue";
import HeaderBar from "@/components/HeaderBar";
import { ElMessage, ElMessageBox } from "element-plus";
import { useMainStore } from "@/stores";
import { usePartsStore } from "@/stores/parts";
import ExcludeObject from "./discharge/ExcludeObject.vue";
import AppFocusableContainer from "./discharge/AppFocusableContainer.vue";
const store = useMainStore();
// 唤醒国际化
const { $t } = getCurrentInstance().proxy;
let state = reactive({
  // 折叠面板
  foldFlag: true,
  // svg数据获取成功
  dataReady: false,
  socket: null,
  excluded_data: [],
  partCheckedData: "",
  // 排除了哪些对象主体
  lightObjects: [],
  // k1 网格比例: ; k1max 网格比例:
  viewBoxInt: "",
  deviceStyle: false,
});
let excludeObject = ref(null);
// gcode文件的排除对象总数
watch(
  [() => store.objects, () => store.excluded_objects, () => store.model],
  () => {
    // 先确定机器的画布网格
    if (store.model) {
      // K1Max的viewBoxInt, 300x300x300
      if (store.model === "K1 Max") {
        console.log("当前打印机型号", store.model);
        state.viewBoxInt = "-26 0 350 300";
        state.deviceStyle = true;
        // K1的viewBoxInt, 220x220x250
      } else {
        state.viewBoxInt = "0 0 300 290";
        state.deviceStyle = false;
      }
    }
    if (store.objects && store.objects.length > 0) {
      state.excluded_data = JSON.parse(store.objects);
      console.log("排除对象数据监听到了阿", JSON.parse(store.objects));
      // if (state.excluded_data.length > 0) {
      //   state.excluded_data = state.excluded_data.reverse();
      // }
      usePartsStore().partUpdate(state.excluded_data);
    }
    // 已经处于排除阶段的对象
    if (store.excluded_objects) {
      // console.log("store.excluded_objects", state.lightObjects.length);
      // 把数据传递给子组件，让其高亮已经被排除的对象
      state.lightObjects = JSON.parse(store.excluded_objects);
    }
  },
  { immediate: true }
);
// // 监听当前机器的大小，品牌相关
// watch(
//   () => store.model,
//   (val) => {
//     if (val === "K1 Max") {
//       console.log("当前打印机型号", val);
//       state.isK1Device = false;
//     }
//   }
// );
// 折叠面板
const foldThePage = () => {
  // console.log("准备缩小面板啦");
  state.foldFlag = false;
};
const getImageUrl = () => {
  if (state.deviceStyle) {
    return require(`../../../assets/img/k1max.jpg`);
  } else {
    return require(`../../../assets/img/k1device.png`);
  }
};
// 展开面板
const openThePage = () => {
  // console.log("准备展开面板啦");
  state.foldFlag = true;
};
const disabled = ref(true);
const container = ref(null);
const svgElement = ref(null);
const focused = ref(false);
// 是否是Delta机型
// const isDelta = ref(false);
// const showAnimations = ref(true);
// const autoZoom = ref(true);
// const flipX = ref(false);
// const flipY = ref(true);
let panzoomDom = null;
onMounted(() => {
  // 请求
  const { proxy } = getCurrentInstance();
  state.socket = proxy.$webSocket;
  // 排除对象的数据，获取一次就行了，因为路径坐标，边线坐标，在模型打印的前夕就已经确定了，固，有数据了，就停止定时器即可
  let timer = setInterval(() => {
    state.socket.sendMsg({
      method: "get",
      params: {
        reqPrintObjects: 1,
      },
    });
    // console.log("排除对象数据界面查询");
    if (state.excluded_data && state.excluded_data.length > 0) {
      clearTimeout(timer);
      timer = null;
    }
  }, 2000);
  // 10秒后撤掉定时器
  setTimeout(() => {
    clearTimeout(timer);
    timer = null;
  }, 30000);
});

onBeforeUnmount(() => {
  panzoomDom?.dispose();
});
// 排除的svg对象点击
const partCheckedFun = (name) => {
  // console.log("点击的是什么数据阿", name);
  state.partCheckedData = name;
};
// 排除对象方法
const excludeObjectFun = (part) => {
  if (state.lightObjects.includes(part)) {
    ElMessage({
      type: "warning",
      message: $t("configuration.paichuguol"),
    });
    return;
  }
  ElMessageBox.confirm(
    $t("configuration.surePaichu"),
    $t("configuration.paichuObject"),
    {
      confirmButtonText: $t("configuration.sureInfo"),
      cancelButtonText: $t("configuration.cancel"),
      type: "warning",
    }
  )
    .then(() => {
      // 调用排除对象接口
      state.socket.sendMsg({
        method: "set",
        params: {
          excludeObjects: [part],
        },
      });
      ElMessage({
        type: "success",
        message: $t("configuration.paichuSuccess"),
      });
    })
    .catch(() => {
      // 取消的话就把高亮的颜色置灰
      excludeObject.value.isPartsChecked = "";
      ElMessage({
        type: "info",
        message: $t("configuration.quxiao"),
      });
    });
};
</script>
<style lang="scss" scoped>
.controlAnimal {
  height: 40px !important;
}
.control-main {
  // 不放首页
  // width: 58.8%;
  // 放首页
  width: 100%;
  // min-width: 500px;
  @include theme_layout();
  .fold {
    cursor: pointer;
    .iconfont {
      color: #d2d2da;
      font-size: 8px;
    }
  }
  .head-show {
    width: 81%;
  }
  // 兼容移动设备
  @media screen and (max-width: 1200px) {
    .middleContent {
      height: 30.7747rem !important;
    }
  }
  // 背景网格的魅力
  .middleContent {
    width: 100%;
    display: flex;
    height: 57.3rem;
    overflow: hidden;
    border-radius: 10px;
    justify-content: flex-start;
    // background-image: url("../../../assets/img/k1max.jpg");
    background-size: cover;
    .active {
      background: #00a3ff;
    }
    .v-input-wrap {
      .v-input__slot {
        #parts {
          // transform: translateY(-47px) !important;
        }
        svg {
          width: 100%;
          height: 916px;
          transform: rotate(180deg) rotateY(180deg);
        }
        // 兼容移动设备
        @media screen and (max-width: 1200px) {
          svg {
            height: 500px;
          }
        }
      }
    }
  }
}
</style>
