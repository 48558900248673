/** 首页控制指令 */
export const CONTROL_INSTRUCTION = {
  homeXY: "homeXY",
  reduceX: "reduceX",
  addX: "addX",
  addY: "addY",
  addZ: "addZ",
  reduceY: "reduceY",
  homeZ: "homeZ",
  reduceZ: "reduceZ",
  homeX: "homeX",
  homeY: "homeY",
  topZ: "topZ",
  bottomZ: "bottomZ",
  saveZ: "saveZ",
  goBack: "goBack",
  goOut: "goOut",
};
